exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".Password-textAlignRight-4KnjQ{\n    text-align: right;\n}\n.Password-textAlignCenter-2hmKA {\n    text-align: center;\n}\n.Password-btnWidth-nXSZh {\n  width: 100%;\n  max-width: 220px;\n  margin: auto 0 auto auto;\n}\n.Password-titleBottom-H1ILg {\n  position: relative;\n}\n.Password-titleBottom-H1ILg::after {\n  bottom: 40px !important;\n  left: 0 !important;\n}\n.Password-changePassPadding-4jdyi {\n  padding-right: 5px;\n} \n ", "", {"version":3,"sources":["/root/web/src/components/Shop/Password/Password.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;CACrB;AACD;IACI,mBAAmB;CACtB;AACD;EACE,YAAY;EACZ,iBAAiB;EACjB,yBAAyB;CAC1B;AACD;EACE,mBAAmB;CACpB;AACD;EACE,wBAAwB;EACxB,mBAAmB;CACpB;AACD;EACE,mBAAmB;CACpB","file":"Password.css","sourcesContent":[".textAlignRight{\n    text-align: right;\n}\n.textAlignCenter {\n    text-align: center;\n}\n.btnWidth {\n  width: 100%;\n  max-width: 220px;\n  margin: auto 0 auto auto;\n}\n.titleBottom {\n  position: relative;\n}\n.titleBottom::after {\n  bottom: 40px !important;\n  left: 0 !important;\n}\n.changePassPadding {\n  padding-right: 5px;\n} \n "],"sourceRoot":""}]);

// exports
exports.locals = {
	"textAlignRight": "Password-textAlignRight-4KnjQ",
	"textAlignCenter": "Password-textAlignCenter-2hmKA",
	"btnWidth": "Password-btnWidth-nXSZh",
	"titleBottom": "Password-titleBottom-H1ILg",
	"changePassPadding": "Password-changePassPadding-4jdyi"
};