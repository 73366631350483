import gql from 'graphql-tag';
import { toastr } from 'react-redux-toastr';
import { setLoaderStart, setLoaderComplete } from '../../actions/loader/loader';

export function updateDriverStatus(id, userStatus) {

	return async (dispatch, getState, { client }) => {
		let errorMessage = 'Oops! something went wrong. Try again!';
		const mutation = gql`
        
        mutation updateDriverStatus(
            $id: ID,
            $userStatus: String,
            ) {
            updateDriverStatus(
              id: $id
              userStatus: $userStatus
            )
            {
              status
              errorMessage
            }
          }
        `;


		dispatch(setLoaderStart('EditDriver'));

		const { data } = await client.mutate({
			mutation,
			variables: {
				id,
				userStatus
			}
		});

		dispatch(setLoaderComplete('EditDriver'));

		if (data && data.updateDriverStatus && data.updateDriverStatus.status === 200) {
			toastr.success('Success!', 'The driver status has been updated successfully.')
		} else {
			errorMessage = (data && data.updateDriverStatus && data.updateDriverStatus.errorMessage) ? data.updateDriverStatus.errorMessage : errorMessage;
			toastr.error("Error!", errorMessage);
		}
	}
}