import React, { Component } from 'react';
import cookie from 'react-cookies';
import { injectIntl, FormattedMessage } from 'react-intl';
import { flowRight as compose } from 'lodash';
import {
    Modal, Button
} from 'react-bootstrap';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';

import messages from '../../locale/messages';

import demoIcon from '../../../public/Icons/notificationIcon.png';
import checkIcon from '../../../public/Icons/checkIcon.png'
import waveImage from '../../../public/Icons/waveImage.svg';

import bt from '../../components/commonStyle.css';
import s from './DemoRestrictionModal.css';
class DemoRestrictionModel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isCookiesSet: false,
            isPageLoad: false,
        };
    }
    componentDidMount() {
        let cookiesValue = cookie.load('demoContent');
        this.setState({
            isPageLoad: true,
            isCookiesSet: (cookiesValue) ? true : false,
        });
    }
    handleClosePopup = () => {
        let now = new Date(), time = now.getTime();
        time += 3600 * 1000;
        now.setTime(time);
        cookie.save('demoContent', 'demoContent', {
            path: '/',
            expires: now
        })
        this.setState({ isCookiesSet: true })
    }
    render() {
        const { formatMessage } = this.props.intl;
        const { isPageLoad, isCookiesSet } = this.state;
        if (isCookiesSet) {
            return <span />
        } else {
            return (
                <div>
                    <Modal show={isPageLoad} onHide={this.handleClosePopup} className={'demoRestrictionModal'}>
                        <Modal.Header closeButton>
                        </Modal.Header>
                        <Modal.Body>
                            <div className={s.restrictionGrid}>
                                <div className={s.dFlex}>
                                    <img src={demoIcon} />
                                </div>
                                <div className={s.overAllPadding}>
                                    <h1 className={cx(s.title, 'titleRestrictionDark')}><FormattedMessage {...messages.headsUp} /></h1>
                                    <div className={s.content}>
                                        {formatMessage(messages.demoInfo)}
                                        {' '} <span className={s.boldContent}>{formatMessage(messages.demoInfo1)}</span>
                                        {' '} <span>{formatMessage(messages.demoInfo2)}</span>
                                        <a href='mailto: support@radicalstart.com' className={s.linkColor}>
                                            <span> {formatMessage(messages.emailInfo1)} </span>
                                        </a>
                                        {formatMessage(messages.whatsUpInfo)}
                                        <a href='http://wa.me/919626360033' target='_blank' className={s.linkColor}>
                                            <span> {formatMessage(messages.whatsUpInfo1)} </span>
                                        </a>
                                    </div>
                                    <div className={s.alignCenter}>
                                        <Button type="button"
                                            className={cx(bt.btnPrimary, s.btnCustomCss)}
                                            onClick={this.handleClosePopup}  >
                                            <img src={checkIcon} className={cx(s.imgWidth)} /> {''}
                                            {formatMessage(messages.gotIt1)}
                                        </Button>
                                    </div>
                                    <div className={s.waveImage}>
                                        <img src={waveImage} />
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            )
        }
    }
}
export default compose(
    injectIntl,
    withStyles(bt, s),
)(DemoRestrictionModel);