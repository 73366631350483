exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".ModifierCommonType-displayInline-1j8fL {\n\tdisplay: inline-block;\n\tmargin-left: 19px;\n}\n.ModifierCommonType-firstSection-3jHEq {\n\twidth: 100%;\n\tmax-width: 320px;\n}\n.ModifierCommonType-highlight-nWlb- {\n    color: #1A1B1C;\n    font-weight: bold;\n}\n.ModifierCommonType-noResult-31z4x {\n\tpadding: 6px 0px 0px 0px;\n\tfont-weight: 500;\n\tfont-size: 16px;\n\tposition: absolute;\n\tcolor: red;\n}\n@media screen and (max-width: 767px) {\n\t.ModifierCommonType-firstSection-3jHEq {\n\t\twidth: 93%;\n\t}\n\t.ModifierCommonType-displayInline-1j8fL {\n\t\tdisplay: block;\n\t\tmargin-left: 0;\n\t\ttext-align: right;\n\t\tmargin: 15px 0;\n\t}\n}", "", {"version":3,"sources":["/root/web/src/components/Shop/ManageMenu/ItemList/ModifierCommonType/ModifierCommonType.css"],"names":[],"mappings":"AAAA;CACC,sBAAsB;CACtB,kBAAkB;CAClB;AACD;CACC,YAAY;CACZ,iBAAiB;CACjB;AACD;IACI,eAAe;IACf,kBAAkB;CACrB;AACD;CACC,yBAAyB;CACzB,iBAAiB;CACjB,gBAAgB;CAChB,mBAAmB;CACnB,WAAW;CACX;AACD;CACC;EACC,WAAW;EACX;CACD;EACC,eAAe;EACf,eAAe;EACf,kBAAkB;EAClB,eAAe;EACf;CACD","file":"ModifierCommonType.css","sourcesContent":[".displayInline {\n\tdisplay: inline-block;\n\tmargin-left: 19px;\n}\n.firstSection {\n\twidth: 100%;\n\tmax-width: 320px;\n}\n.highlight {\n    color: #1A1B1C;\n    font-weight: bold;\n}\n.noResult {\n\tpadding: 6px 0px 0px 0px;\n\tfont-weight: 500;\n\tfont-size: 16px;\n\tposition: absolute;\n\tcolor: red;\n}\n@media screen and (max-width: 767px) {\n\t.firstSection {\n\t\twidth: 93%;\n\t}\n\t.displayInline {\n\t\tdisplay: block;\n\t\tmargin-left: 0;\n\t\ttext-align: right;\n\t\tmargin: 15px 0;\n\t}\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"displayInline": "ModifierCommonType-displayInline-1j8fL",
	"firstSection": "ModifierCommonType-firstSection-3jHEq",
	"highlight": "ModifierCommonType-highlight-nWlb-",
	"noResult": "ModifierCommonType-noResult-31z4x"
};