import addItem from '../../../../../actions/shop/menu/addItem';
import showToaster from '../../../../../helpers/showToaster';
import { demoRestriction } from '../../../../../demo';
import demoRestrictionToaster from '../../../../../helpers/demoRestrictionToaster';

async function submit(values, dispatch) {

     if (demoRestriction.manageItem && demoRestriction.manageItem.indexOf(values.id) >= 0) {
          demoRestrictionToaster();
          return;
     }

     let modifiers = JSON.stringify(values.modifiers), tax = values?.tax, variables;

     if (!values?.tax || (values?.tax?.toString().trim() === '')) {
          tax = null;
     }

     variables = Object.assign({}, values, {
          modifiers
     },
          {
               tax
          });

     values?.image ? await dispatch(addItem(variables)) : showToaster({ messageId: 'addItemError', toasterType: 'error' })
}

export default submit;