import React, { Component } from 'react'
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './SiteSettings.css';
import SiteSettingsForm from '../../../components/SiteAdmin/SiteSettingsForm';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import getSiteSettings from './getSiteSettings.graphql'
import Loader from '../../../components/Common/Loader/Loader';
//message
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';
import messages from '../../../locale/messages';

export class SiteSettings extends Component {

  render() {
    const { getSiteSettings: { loading, getSiteSettings, refetch } } = this.props;
    let siteSettingsCollection = {}

    if (!loading) {
      getSiteSettings.map((item) => {
        siteSettingsCollection[item.name] = item.value
      })
    }

    return (
      <Loader type={"page"} show={loading}>
        < div className={s.root} >
          <div className={cx(s.container, 'paddingBottomLg')}>
            <div className={s.heading}>
              <FormattedMessage {...messages.siteSettingsHeading} />
            </div>
            <div className={s.spaceTop5}>
              {!loading && <SiteSettingsForm refetch={refetch} getSiteSettings={getSiteSettings} initialValues={siteSettingsCollection} />}
            </div>
          </div>
        </div >
      </Loader >
    )
  }
}


export default compose(
  withStyles(s),
  graphql(getSiteSettings, {
    name: 'getSiteSettings',
    options: (props) => ({
      variables: {
        type: 'site_settings',
      },
      ssr: true,
      fetchPolicy: 'network-only'
    })
  })
)(SiteSettings)
