exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".VerifyPhoneNumberForm-verifyBtn-3NjWI {\n    display: inline-block;\n    margin-right: 12px;\n}\n.VerifyPhoneNumberForm-textVrify-1mavW {\n    text-align: right;\n    margin-top: 28px;\n\n}\n.VerifyPhoneNumberForm-labelColor-KojO6 {\n    font-size: 16px;\n    color: #39483A;\n}\n.VerifyPhoneNumberForm-vtrMiddle-2wswr{\n    vertical-align: middle;\n    display: inline-block;\n}\n@media screen and (max-width: 1200px) {\n    .VerifyPhoneNumberForm-textVrify-1mavW {\n        text-align: center;\n    }\n    .VerifyPhoneNumberForm-verifyBtn-3NjWI {\n        display: block;\n        margin-right: 0px;\n        margin-bottom: 24px;\n    }\n    .VerifyPhoneNumberForm-btnPadding-267vT {\n        padding: 12px 15px !important;\n    }\n}", "", {"version":3,"sources":["/root/web/src/components/Shop/EditProfileForm/VerifyPhoneNumberForm/VerifyPhoneNumberForm.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,mBAAmB;CACtB;AACD;IACI,kBAAkB;IAClB,iBAAiB;;CAEpB;AACD;IACI,gBAAgB;IAChB,eAAe;CAClB;AACD;IACI,uBAAuB;IACvB,sBAAsB;CACzB;AACD;IACI;QACI,mBAAmB;KACtB;IACD;QACI,eAAe;QACf,kBAAkB;QAClB,oBAAoB;KACvB;IACD;QACI,8BAA8B;KACjC;CACJ","file":"VerifyPhoneNumberForm.css","sourcesContent":[".verifyBtn {\n    display: inline-block;\n    margin-right: 12px;\n}\n.textVrify {\n    text-align: right;\n    margin-top: 28px;\n\n}\n.labelColor {\n    font-size: 16px;\n    color: #39483A;\n}\n.vtrMiddle{\n    vertical-align: middle;\n    display: inline-block;\n}\n@media screen and (max-width: 1200px) {\n    .textVrify {\n        text-align: center;\n    }\n    .verifyBtn {\n        display: block;\n        margin-right: 0px;\n        margin-bottom: 24px;\n    }\n    .btnPadding {\n        padding: 12px 15px !important;\n    }\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"verifyBtn": "VerifyPhoneNumberForm-verifyBtn-3NjWI",
	"textVrify": "VerifyPhoneNumberForm-textVrify-1mavW",
	"labelColor": "VerifyPhoneNumberForm-labelColor-KojO6",
	"vtrMiddle": "VerifyPhoneNumberForm-vtrMiddle-2wswr",
	"btnPadding": "VerifyPhoneNumberForm-btnPadding-267vT"
};