import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import cx from 'classnames';
import s from './ChangePassword.css';
import Password from '../../../components/Shop/Password/Password';

class ChangePassword extends React.Component {

    static propTypes = {
        title: PropTypes.string
    }

    render() {
        const { title, userId } = this.props;
        let initialValues = {
            userId
        }

        return (
            <div>
                <div>
                    <Password initialValues={initialValues}/>
                </div>
            </div>
        )
    }

}

export default withStyles(s)(ChangePassword);