
import showToaster from '../../helpers/showToaster';
import { salesUser } from '../../demo';

export function demoRestriction() {
    return async (dispatch, getState, { client }) => {
        try {
            const adminId =  getState().account && getState().account.privileges && getState().account.privileges.id;
            if (salesUser.indexOf(adminId) < 0) {
                showToaster({ messageId: 'demoRestriction', toasterType: 'warning' });
                return true;
            } else return false;
        } catch (error) {
            return false;
        }

    };
}