import { addCancelReason } from '../../../actions/siteadmin/CancelReason/addCancelReason';
import { manageCancelReasons } from '../../../demo';
import { demoRestriction } from '../../../actions/siteadmin/demoRestriction';

async function submit(values, dispatch) {
    if (manageCancelReasons.indexOf(Number(values.id)) > -1) {
        const isRestrict = await dispatch(demoRestriction());
        if (isRestrict) return;
    }
    await dispatch(addCancelReason(values))
}

export default submit;