import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, reset } from 'redux-form';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag'
import { flowRight as compose } from 'lodash'
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import FormGroup from 'react-bootstrap/FormGroup';
import Col from 'react-bootstrap/Col';
import FormControl from 'react-bootstrap/FormControl';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';

import Loader from '../../Common/Loader';

import { setLoaderStart, setLoaderComplete } from '../../../actions/loader/loader';
import messages from '../../../locale/messages'
import validate from './validate';
import showToaster from '../../../helpers/showToaster';
import { demoRestriction } from '../../../actions/siteadmin/demoRestriction';

import ShowPassword from '../../../../public/Icons/pswVisible.svg';
import HidePassword from '../../../../public/Icons/pwdHidden.svg';

import s from './ChangeAdminForm.css';

class ChangeAdminForm extends Component {


  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.state = {
      password: false,
      confirmPassword: false
    }
  }

  static defaultProps = {
    loading: false
  };

  handleChange(fieldName) {
    this.setState({ [fieldName]: !this.state[fieldName] });
  }

  renderFormControl = ({ input, label, type, meta: { touched, error }, className, note, showPassword }) => {
    const { formatMessage } = this.props.intl
    return (
      <FormGroup className={s.space3}>
        <div>
          <Col xs={12} sm={12} md={12} lg={12}>
            <label className={s.labelText} >{label}</label>
            <div className={'positionRelative'}>
              <FormControl {...input} placeholder={label} type={showPassword ? 'text' : type} className={className} />
              {type == 'password' && <span onClick={() => this.handleChange(input.name)} className={'pwdShowIconSection'}>
                {showPassword ? <img src={ShowPassword} /> : <img src={HidePassword} />}
              </span>}
            </div>
            {touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
            {
              note && <p className={cx(s.subtext, 'textRightRTL')}>{note}</p>
            }
          </Col>
        </div>
      </FormGroup>
    );
  }

  async submitForm(values, dispatch) {
    
    const { demoRestriction } = this.props;
    const isRestrict = await demoRestriction();
    if (isRestrict) return;

    dispatch(setLoaderStart('changePassword'));

    const { mutate } = this.props;
    const { data } = await mutate({ variables: values });

    if (data && data.changeAdminUser) {
      if (data.changeAdminUser.status == '200') {
        showToaster({ messageId: 'changeAdminUser', toasterType: 'success' })
      } else if (data.changeAdminUser.status === 'email') {
        showToaster({ messageId: 'emailExist', toasterType: 'error' })
      } else {
        showToaster({ messageId: 'changeAdminUserErr', toasterType: 'error' })
      }
    }

    dispatch(setLoaderComplete('changePassword'));
    dispatch(reset('ChangeAdminForm'));

  }

  render() {

    const { error, handleSubmit, submitting, dispatch, title, isSuperAdmin, loading } = this.props;
    const { formatMessage } = this.props.intl;
    const { password, confirmPassword } = this.state;
    return (
      <div className={cx(s.space5, s.spaceTop5)}>
        <Container fluid>
          <Row>
            <Col lg={6} md={12} sm={12} xs={12} className={s.blackCenter}>
              <div className={s.blackCenterSection}>
                <form onSubmit={handleSubmit(this.submitForm)}>
                  {error && <strong>{error}</strong>}
                  {
                    <Field
                      name="email"
                      type="text"
                      component={this.renderFormControl}
                      label={formatMessage(messages.email)}
                      note={formatMessage(messages.emailNotes)}
                      className={s.formControlInput}
                    />
                  }
                  <Field name="password" type="password" component={this.renderFormControl} label={formatMessage(messages.password)} className={cx(s.formControlInput, "inputFieldWithIcon")} showPassword={password} />
                  <Field name="confirmPassword" type="password" component={this.renderFormControl} label={formatMessage(messages.confirmPassword)} className={cx(s.formControlInput, "inputFieldWithIcon")} showPassword={confirmPassword} />
                  <FormGroup className={s.noMargin}>
                    <Col xs={12} sm={12} md={12} lg={12} className={cx(s.textAlignRight, 'textLeftRTL', 'borderBottomRTL', 'pwdBtnRTL')}>
                      <div className={s.displayInlineBlock}>
                        <Loader
                          type={"button"}
                          label={formatMessage(messages.submitButton)}
                          show={loading}
                          buttonType={'submit'}
                          className={cx(s.button, s.btnPrimary)}
                          disabled={submitting || loading}
                          isSuffix={true}
                        />
                      </div>
                    </Col>
                  </FormGroup>
                </form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

}

ChangeAdminForm = reduxForm({
  form: 'ChangeAdminForm',
  validate
})(ChangeAdminForm);

const mapState = (state) => ({
  isSuperAdmin: state.runtime.isSuperAdmin,
  loading: state.loader.changePassword
});

const mapDispatch = {
  demoRestriction
};

export default injectIntl(compose(
  withStyles(s),
  connect(mapState, mapDispatch),
  graphql(gql`
    mutation changeAdminUser($email: String, $password: String!) {
      changeAdminUser (email: $email, password: $password) {
        status
      }
    }
  `),
)(ChangeAdminForm));