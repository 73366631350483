function censorText(text) {
    let textLength = 2;
    let textCount = 0, outputText;
    if (text) {
        if(text.length <= textLength) {
            return text;
        }
        
        textCount = text.length - textLength;
        if (textCount <= 2) {
        	outputText = text[0] + '*'.repeat(Math.max(textCount, 0)) + text.slice(-1);
        } else {
        	outputText = text[0];
          let i = 1;
          while(i <= textCount) {
          	outputText = outputText + ((i%2 == 0) ? text[i] : '*');
            i++;
          }
          outputText = outputText + text.slice(-1);
        }
        
        return outputText;
    } else {
        return text;
    }
}

function censorPhoneNumber(phoneNumber) {
    return censorText(phoneNumber);
}

function censorEmail(email) {
    if (email) {
        let emailSplit = email.split("@");
        let emailSecondarySplit = emailSplit && emailSplit.length > 1 && emailSplit[1].split('.');
        return emailSplit && emailSplit.length > 0 && censorText(emailSplit[0]) + "@" + censorText(emailSecondarySplit[0]) + '.' + censorText(emailSecondarySplit[1]);
    } else {
        return email;
    }
}

export {
    censorText,
    censorPhoneNumber,
    censorEmail
};