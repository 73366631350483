import React, { Component } from 'react'
import { Field, reduxForm, formValueSelector, initialize, change } from 'redux-form';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import cx from 'classnames';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Badge from 'react-bootstrap/Badge';
import Container from 'react-bootstrap/Container';

import Link from '../../Link';
import Loader from '../../Common/Loader';
import CountryList from '../../CountryList';
import ImageUploadComponent from '../../ImageUploadComponent/ImageUploadComponent';

import uploadLicenceFrontImage from '../../../actions/siteadmin/uploadLicenceFrontImage';
import uploadLicenceBackImage from '../../../actions/siteadmin/uploadLicenceBackImage';
import uploadRcbookImage from '../../../actions/siteadmin/uploadRcbookImage';
import uploadInsuranceImage from '../../../actions/siteadmin/uploadInsuranceImage';
import uploadProfileImage from '../../../actions/siteadmin/uploadProfileImage';
import submit from './submit';
import validate from './validate';
import messages from '../../../locale/messages';
import { api, profilePhotouploadDir, licenseuploadDir, vehicleUploadDir } from '../../../config';
import { updateDriverStatus } from '../../../actions/siteadmin/updateDriverStatus'

import s from './EditDriverForm.css'

export class EditDriverForm extends Component {

    constructor(props) {
        super(props)

        this.state = {
            countryCode: 'IN',
            country: '+91'
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleCountryChange = this.handleCountryChange.bind(this);
        this.handleStatus = this.handleStatus.bind(this)
    }

    static defaultProps = {
        loading: false
    };

    renderSelectField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder, children }) => {
        const { formatMessage } = this.props.intl
        return (
            <Form.Group>
                <Form.Label>{label}</Form.Label>
                <Form.Control as="select" {...input} placeholder={placeholder} className={fieldClass}>
                    {children}
                </Form.Control>
                {touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
            </Form.Group>
        )
    }


    renderField = ({ input, label, type, meta: { touched, error }, fieldClass, placeholder, disabled }) => {
        const { formatMessage } = this.props.intl
        return (
            <Form.Group>
                <Form.Label>{label}</Form.Label>
                <Form.Control {...input} placeholder={placeholder} type={type} className={fieldClass} disabled={disabled} />
                {touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
            </Form.Group>
        )
    }

    renderFieldPhoneNumber = ({ input, label, writeOnce, type, meta: { touched, error }, fieldClass, placeholder, disabled }) => {
        const { formatMessage } = this.props.intl
        return (
            <Form.Group>
                <Form.Control {...input} readOnly={writeOnce} placeholder={placeholder} type={type} className={fieldClass} disabled={disabled} />
                {touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
            </Form.Group>
        )
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleCountryChange(e, selectedData) {
        this.setState({
            country: selectedData.dialCode,
            countryCode: selectedData.countryCode
        });
    }

    async handleStatus(e) {
        const { updateDriverStatus, id } = this.props;
        await updateDriverStatus(id, e.target.value);
    }


    componentDidMount() {
        const { initialValues, initialize } = this.props;
        if (initialValues && initialValues.country && initialValues.phoneCountryCode) {
            this.setState({
                countryCode: initialValues.phoneCountryCode,
                country: initialValues.country
            });
        }
    }



    handleDropzone = async (fileName, fieldName) => {
        const { id, picture, licenceFront, licenceBack, rcBookImage, insuranceImage } = this.props;
        const { change, uploadProfileImage, uploadLicenceFrontImage, uploadLicenceBackImage, uploadRcbookImage, uploadInsuranceImage } = this.props;

        await change(fieldName, fileName);

        switch (fieldName) {
            case 'licenceFront':
                await uploadLicenceFrontImage(id, fileName, licenceFront);
                break;
            case 'licenceBack':
                await uploadLicenceBackImage(id, fileName, licenceBack);
                break;
            case 'rcBookImage':
                await uploadRcbookImage(id, fileName, rcBookImage);
                break;
            case 'insuranceImage':
                await uploadInsuranceImage(id, fileName, insuranceImage);
                break;
            default:
                await uploadProfileImage(id, fileName, picture);
        }
    }

    render() {
        const { handleSubmit, id, picture, licenceFront, licenceBack, loading, submitting, getDriver, rcBookImage, insuranceImage } = this.props
        const { formatMessage } = this.props.intl;
        const { countryCode } = this.state;
        const { vehicleTypes, profileUploader, licenceFrontLoader, licenceBackLoader, rcBookImageLoader, insuranceImageLoader } = this.props;

        return (
            <div className={cx(s.space5, s.spaceTop5, 'tabLabelText')}>
                <Container fluid>
                    <Row>
                        <Col xl={10} lg={12} md={12} sm={12} xs={12} className={s.blackCenter}>
                            <div className={s.blackCenterSection}>
                                <Form className={s.fullWidth} onSubmit={handleSubmit(submit)}>
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <h1 className={cx(s.titleTextAdmin, 'textRightRTL')}>
                                            {formatMessage(messages.editDriver)}
                                            <span className={s.userStatusBadge}>
                                                <Badge pill variant={getDriver && getDriver.isActive === 1 && getDriver.isBan == 0 ? 'success' : 'danger'}>
                                                    {getDriver && getDriver.isActive === 1 && getDriver.isBan == 0 ? formatMessage(messages.online) : formatMessage(messages.offline)}
                                                </Badge>
                                                {' '}
                                                {getDriver && getDriver.isActive === 1 && getDriver.isBan == 0 && <Badge pill variant={getDriver.activeStatus === "active" ? 'success' : 'danger'}>
                                                    {getDriver.activeStatus === "active" ? formatMessage(messages.eligibleForADelivery) : formatMessage(messages.notEligibleForADelivery)}
                                                </Badge>}
                                            </span>
                                        </h1>
                                    </Col>
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <Row>
                                            <Col xl={4} lg={12} md={12} sm={12} xs={12}>
                                                <Form.Group className={s.space3}>
                                                    <div className={cx(s.profileImgSection, s.profileImgWidth)}>
                                                        <div>
                                                            <ImageUploadComponent
                                                                className={cx(s.btnSecondary, s.profileNoPadding)}
                                                                subTextClass={s.subText}
                                                                subText={formatMessage(messages.maximumUploadSizeLabel)}
                                                                defaultMessage={formatMessage(messages.chooseFile)}
                                                                loaderName={'profileUploader'}
                                                                postUrl={api.apiEndpoint + '/uploadProfileImage'}
                                                                loader={profileUploader}
                                                                fieldName={'picture'}
                                                                imageSrc={picture ? api.apiEndpoint + profilePhotouploadDir + picture : null}
                                                                label={formatMessage(messages.profilePicture)}
                                                                inputContainer={'.dzInputContainerProfilePicture'}
                                                                inputContainerClass={'dzInputContainerProfilePicture'}
                                                                handleDropzone={this.handleDropzone}
                                                                isDemoRestriction
                                                            />
                                                        </div>
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                            <Col xl={4} lg={6} md={12} sm={12} xs={12}>
                                                <Form.Group className={s.space3}>
                                                    <div className={cx(s.profileImgSection, s.profileImgWidth)}>
                                                        <div>
                                                            <ImageUploadComponent
                                                                className={cx(s.btnSecondary, s.profileNoPadding)}
                                                                subTextClass={s.subText}
                                                                subText={formatMessage(messages.maximumUploadSizeLabel)}
                                                                defaultMessage={formatMessage(messages.chooseFile)}
                                                                loaderName={'licenceFrontLoader'}
                                                                postUrl={api.apiEndpoint + '/uploadLicenceImage'}
                                                                loader={licenceFrontLoader}
                                                                fieldName={'licenceFront'}
                                                                imageSrc={licenceFront ? api.apiEndpoint + licenseuploadDir + licenceFront : null}
                                                                label={formatMessage(messages.licenceFrontImage)}
                                                                inputContainer={'.dzInputContainerLicenceFront'}
                                                                inputContainerClass={'dzInputContainerLicenceFront'}
                                                                handleDropzone={this.handleDropzone}
                                                                isDemoRestriction
                                                            />
                                                        </div>
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                            <Col xl={4} lg={6} md={12} sm={12} xs={12}>
                                                <Form.Group className={s.space3}>
                                                    <div className={cx(s.profileImgSection, s.profileImgWidth)}>
                                                        <div>
                                                            <ImageUploadComponent
                                                                className={cx(s.btnSecondary, s.profileNoPadding)}
                                                                subTextClass={s.subText}
                                                                subText={formatMessage(messages.maximumUploadSizeLabel)}
                                                                defaultMessage={formatMessage(messages.chooseFile)}
                                                                loaderName={'licenceBackLoader'}
                                                                postUrl={api.apiEndpoint + '/uploadLicenceImage'}
                                                                loader={licenceBackLoader}
                                                                fieldName={'licenceBack'}
                                                                imageSrc={licenceBack ? api.apiEndpoint + licenseuploadDir + licenceBack : null}
                                                                label={formatMessage(messages.licenceBackImage)}
                                                                inputContainer={'.dzInputContainerLicenceBack'}
                                                                inputContainerClass={'dzInputContainerLicenceBack'}
                                                                handleDropzone={this.handleDropzone}
                                                                isDemoRestriction
                                                            />

                                                        </div>
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6} md={12} sm={12} xs={12}>
                                                <Form.Group className={s.space3}>
                                                    <div className={cx(s.profileImgSection, s.profileImgWidth)}>
                                                        <div>
                                                            <ImageUploadComponent
                                                                className={cx(s.btnSecondary, s.profileNoPadding)}
                                                                subTextClass={s.subText}
                                                                subText={formatMessage(messages.maximumUploadSizeLabel)}
                                                                defaultMessage={formatMessage(messages.chooseFile)}
                                                                loaderName={'rcBookImageLoader'}
                                                                postUrl={api.apiEndpoint + '/uploadRcbookImage'}
                                                                loader={rcBookImageLoader}
                                                                fieldName={'rcBookImage'}
                                                                imageSrc={rcBookImage ? api.apiEndpoint + vehicleUploadDir + rcBookImage : null}
                                                                label={formatMessage(messages.rcImage)}
                                                                inputContainer={'.dzInputContainerRCBook'}
                                                                inputContainerClass={'dzInputContainerRCBook'}
                                                                handleDropzone={this.handleDropzone}
                                                                isDemoRestriction
                                                            />
                                                        </div>
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6} md={12} sm={12} xs={12}>
                                                <Form.Group className={s.space3}>
                                                    <div className={cx(s.profileImgSection, s.profileImgWidth)}>
                                                        <div>
                                                            <ImageUploadComponent
                                                                className={cx(s.btnSecondary, s.profileNoPadding)}
                                                                subTextClass={s.subText}
                                                                subText={formatMessage(messages.maximumUploadSizeLabel)}
                                                                defaultMessage={formatMessage(messages.chooseFile)}
                                                                loaderName={'insuranceImageLoader'}
                                                                postUrl={api.apiEndpoint + '/uploadInsuranceImage'}
                                                                loader={insuranceImageLoader}
                                                                fieldName={'insuranceImage'}
                                                                imageSrc={insuranceImage ? api.apiEndpoint + vehicleUploadDir + insuranceImage : null}
                                                                label={formatMessage(messages.insuranceImage)}
                                                                inputContainer={'.dzInputContainerInsurance'}
                                                                inputContainerClass={'dzInputContainerInsurance'}
                                                                handleDropzone={this.handleDropzone}
                                                                isDemoRestriction
                                                            />
                                                        </div>
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6} md={12} sm={12} xs={12}>
                                                <Form.Group className={s.space3}>
                                                    <div>
                                                        <Field
                                                            name="firstName"
                                                            type="text"
                                                            placeholder={formatMessage(messages.firstName)}
                                                            component={this.renderField}
                                                            label={formatMessage(messages.firstName)}
                                                            labelClass={s.labelText}
                                                            fieldClass={s.formControlInput}
                                                        />
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6} md={12} sm={12} xs={12}>
                                                <Form.Group className={s.space3}>
                                                    <div>
                                                        <Field
                                                            name="lastName"
                                                            type="text"
                                                            placeholder={formatMessage(messages.lastName)}
                                                            component={this.renderField}
                                                            label={formatMessage(messages.lastName)}
                                                            labelClass={s.labelText}
                                                            fieldClass={s.formControlInput}
                                                        />
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <Row>
                                            <Col lg={6} md={12} sm={12} xs={12}>
                                                <Form.Group className={s.space3}>
                                                    <div>
                                                        <Field
                                                            name="email"
                                                            type="text"
                                                            component={this.renderField}
                                                            placeholder={formatMessage(messages.email)}
                                                            label={formatMessage(messages.email)}
                                                            labelClass={s.labelText}
                                                            fieldClass={s.formControlInput}
                                                            disabled
                                                        />
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6} md={12} sm={12} xs={12}>
                                                <Form.Group className={s.space3}>
                                                    <div>
                                                        <Field
                                                            name="password"
                                                            type="text"
                                                            placeholder={formatMessage(messages.password)}
                                                            component={this.renderField}
                                                            label={formatMessage(messages.password)}
                                                            labelClass={s.labelText}
                                                            fieldClass={s.formControlInput}
                                                        />
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <Col lg={12} md={12} sm={12} xs={12} className={s.noPadding}>
                                                    <label>{formatMessage(messages.phoneNumber)}</label>
                                                </Col>
                                                <Row>
                                                    <Col lg={6} md={12} sm={12} xs={12}>
                                                        <CountryList
                                                            input={
                                                                {
                                                                    name: 'phoneDialCode',
                                                                    onChange: this.handleChange,
                                                                    value: countryCode,
                                                                }
                                                            }
                                                            className={cx(s.formControlSelect, s.formControlInput, s.space1)}
                                                            dialCode={false}
                                                            getSelected={this.handleCountryChange}
                                                            formName={'EditDriverForm'}
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Col lg={6} md={12} sm={12} xs={12} className='noRadiusLeft'>
                                                        <div className={cx(s.displayInlineBlock, s.countryCode)}>
                                                            <Form.Group className={s.space3}>
                                                                <div>
                                                                    <Field
                                                                        name="phoneDialCode"
                                                                        type="text"
                                                                        placeholder={formatMessage(messages.phoneDialCode)}
                                                                        component={this.renderFieldPhoneNumber}
                                                                        labelClass={s.labelText}
                                                                        fieldClass={cx(s.formControlInput, s.formControlInputCountryCode)}
                                                                        writeOnce={true}
                                                                        disabled
                                                                    />
                                                                </div>
                                                            </Form.Group>
                                                        </div>
                                                        <div className={cx(s.displayInlineBlock, s.countryPhoneNumber)}>
                                                            <Form.Group className={s.space3}>
                                                                <div>
                                                                    <Field
                                                                        name="phoneNumber"
                                                                        type="text"
                                                                        placeholder={formatMessage(messages.phoneNumber)}
                                                                        component={this.renderFieldPhoneNumber}
                                                                        labelClass={s.labelText}
                                                                        fieldClass={cx(s.formControlInput, s.formControlInputCountry)}
                                                                        disabled
                                                                    />
                                                                </div>
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col lg={6} md={12} sm={12} xs={12}>
                                                <Form.Group className={s.space3}>
                                                    <div>
                                                        <Field
                                                            name="vehicleName"
                                                            type="text"
                                                            placeholder={formatMessage(messages.vehicleName)}
                                                            component={this.renderField}
                                                            label={formatMessage(messages.vehicleName)}
                                                            labelClass={s.labelText}
                                                            fieldClass={s.formControlInput}
                                                        />
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6} md={12} sm={12} xs={12}>
                                                <Form.Group className={s.space3}>
                                                    <div>
                                                        <Field
                                                            name="vehicleNumber"
                                                            type="text"
                                                            placeholder={formatMessage(messages.vehicleNumber)}
                                                            component={this.renderField}
                                                            label={formatMessage(messages.vehicleNumber)}
                                                            labelClass={s.labelText}
                                                            fieldClass={s.formControlInput}
                                                        />
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={4} md={12} sm={12} xs={12}>
                                                <Form.Group className={s.space3}>
                                                    <div>
                                                        <Field
                                                            name="vehicleType"
                                                            placeholder={formatMessage(messages.vehicleType)}
                                                            component={this.renderSelectField}
                                                            label={formatMessage(messages.vehicleType)}
                                                            labelClass={s.labelText}
                                                            fieldClass={s.formControlInput}
                                                        >
                                                            {/* <option value={''}>{formatMessage(messages.vehicleType)}</option> */}
                                                            {
                                                                vehicleTypes && vehicleTypes.results && vehicleTypes.results.map((item) => {
                                                                    return <option value={item.id}>{item.vehicleName}</option>
                                                                })
                                                            }
                                                        </Field>
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={4} md={12} sm={12} xs={12}>
                                                <Form.Group className={s.space3}>
                                                    <div>
                                                        <label>{formatMessage(messages.userStatusActive)}</label>
                                                        <Field name="userStatus" onChange={(e) => this.handleStatus(e)} className={cx(s.formControlSelect, s.formControlInput)} component="select">
                                                            <option value="pending">{formatMessage(messages.pending)}</option>
                                                            <option value="active">{formatMessage(messages.approve)}</option>
                                                            <option value="inactive">{formatMessage(messages.decline)}</option>
                                                        </Field>
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={4} md={12} sm={12} xs={12}>
                                                <Form.Group className={s.space3}>
                                                    <div>
                                                        <label>{formatMessage(messages.banStatus)}</label>
                                                        <Field name="isBan" className={cx(s.formControlSelect, s.formControlInput)} component="select">
                                                            <option value="0">{formatMessage(messages.permit)}</option>
                                                            <option value="1">{formatMessage(messages.ban)}</option>
                                                        </Field>
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col lg={12} md={12} sm={12} xs={12} className={cx(s.textAlignRight, s.spaceTop1, 'textLeftRTL', 'borderBottomRTL')}>
                                        <Form.Group className={s.noMargin}>
                                            <div className={s.displayInlineBlock}>
                                                <Loader
                                                    type={"button"}
                                                    label={formatMessage(messages.update)}
                                                    show={loading}
                                                    buttonType={'submit'}
                                                    className={cx(s.button, s.btnPrimary)}
                                                    disabled={submitting || loading}
                                                    isSuffix={true}
                                                />
                                            </div>
                                            <Link to={"/siteadmin/drivers"} className={cx(s.backBtn, s.btnSecondary, s.linkBtnInline, 'backBtnRTL')}>{formatMessage(messages.goBack)}</Link>
                                        </Form.Group>
                                    </Col>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div >
        )
    }
}

EditDriverForm = reduxForm({
    form: 'EditDriverForm', // a unique name for this form
    validate,
    onSubmit: submit
})(EditDriverForm)

const selector = formValueSelector('EditDriverForm')
const mapState = state => ({
    id: selector(state, 'id'),
    picture: selector(state, 'picture'),
    licenceFront: selector(state, 'licenceFront'),
    licenceBack: selector(state, 'licenceBack'),
    rcBookImage: selector(state, 'rcBookImage'),
    insuranceImage: selector(state, 'insuranceImage'),
    loading: state.loader.EditDriver,
    profileUploader: state.loader.profileUploader,
    licenceFrontLoader: state.loader.licenceFrontLoader,
    licenceBackLoader: state.loader.licenceBackLoader,
    rcBookImageLoader: state.loader.rcBookImageLoader,
    insuranceImageLoader: state.loader.insuranceImageLoader
})

const mapDispatch = {
    change,
    updateDriverStatus,
    uploadLicenceFrontImage,
    uploadLicenceBackImage,
    uploadRcbookImage,
    uploadInsuranceImage,
    uploadProfileImage
}

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(EditDriverForm)));