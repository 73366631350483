export function censorWord(str) {
    if (str) {
        if(str.length < 2){
            return str;
        }
        var count = str.length - 2;
        return str[0] + "*".repeat(Math.max(count, 0)) + str.slice(-1);
    }
}

export function censorPhone(number) {
    return censorWord(number);
}

export function censorEmail(email) {
    if (email) {
        var arr = email.split("@");
        return censorWord(arr[0]) + "@" + censorWord(arr[1]);
    }
}