import { reset } from 'redux-form';
import fetch from 'node-fetch';
import showToaster from '../../../helpers/showToaster';
import { users } from '../../../demo';
import demoRestrictionToaster from '../../../helpers/demoRestrictionToaster';

async function submit(values, dispatch) {

    if (values.userId === users.store.id) {
        demoRestrictionToaster();
        return;
    }

    
    const query = `
    mutation (
        $currentPassword: String,
        $newPassword: String,
        $confirmNewPassword: String    
    ) {
        changePassword (
            currentPassword: $currentPassword,
            newPassword: $newPassword,
            confirmNewPassword: $confirmNewPassword
        ){
            status
            errorMessage
        }
    }`;


    const response = await fetch('/graphql', {
        method: 'post',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            query: query,
            variables: {
                currentPassword: values.currentPassword,
                newPassword: values.newPassword,
                confirmNewPassword: values.confirmNewPassword
            }
        }),
        credentials: 'include',
    });

    const { data } = await response.json();

    const result = data?.changePassword?.status

    showToaster({
        messageId: result === 200 ? 'changePassword' : 'commonError',
        toasterType: result === 200 ? 'success' : 'error',
        requestContent: result === 200 ? null : data?.changePassword?.errorMessage
    })

    result === 200 && dispatch(reset('ChangePassword'));

}
export default submit;