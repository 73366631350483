import React, { Component } from 'react';
import { Field, reduxForm, getFormValues, change, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl, FormattedMessage } from 'react-intl';
import cx from 'classnames';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FormGroup from 'react-bootstrap/FormGroup';
import FormControl from 'react-bootstrap/FormControl';
import Container from 'react-bootstrap/Container';

import Loader from '../../Common/Loader';
import PlacesSuggest from '../PlacesSuggest';
import CustomCheckbox from '../../Common/CustomCheckbox';
import PhoneNumberVerification from './PhoneNumberVerification/PhoneNumberVerification';
import ImageUploadComponent from '../../ImageUploadComponent/ImageUploadComponent';

import validate from './validate';
import submit from './submit';
import messages from '../../../locale/messages';
import { api, storeImageUploadDir } from '../../../config'

import TrashIcon from '../../../../public/StoreIcon/dlty.png'
import { setLoaderStart, setLoaderComplete } from '../../../actions/loader/loader';
import { users } from '../../../demo';

import NoLogo from '../../../../public/StoreIcon/defaultstoreimage.svg';

import s from './EditProfileForm.css';
import rs from '../../storeCommon.css';

export class EditProfileForm extends Component {

	constructor(props) {
		super(props);

		this.state = {
			phoneCountryCode: '',
			phoneDialCode: '',
			isReadOnly: true,
			phoneStatus: '',
			defaultPreparationTime: 55
		};


		this.handleCountryChange = this.handleCountryChange.bind(this);
		this.handleLocationData = this.handleLocationData.bind(this);
		this.handleDropzone = this.handleDropzone.bind(this);
		this.handleDefaultPreparationTime = this.handleDefaultPreparationTime.bind(this);
		this.handleDeleteImage = this.handleDeleteImage.bind(this);
		this.loaderStart = this.loaderStart.bind(this);
		this.loaderEnd = this.loaderEnd.bind(this);
	}

	static defaultProps = {
		loading: false
	};

	componentWillMount() {
		const { initialValues, phoneStatus, change } = this.props;
		const { defaultPreparationTime } = this.state;
		let preparationTime = initialValues && initialValues.shopEstimatedTime ? initialValues.shopEstimatedTime : defaultPreparationTime;

		change('isPhoneVerified', phoneStatus);
		this.setState({
			phoneCountryCode: initialValues && initialValues.phoneCountryCode,
			phoneDialCode: initialValues && initialValues.phoneDialCode,
			phoneStatus: phoneStatus,
			defaultPreparationTime: preparationTime
		});
	}

	UNSAFE_componentWillReceiveProps(newProps) {
		const { phoneNumber, isPhoneVerified } = newProps;
		if (phoneNumber == null) {
			this.setState({
				isReadOnly: false,
				phoneStatus: false
			})
		}

		if (isPhoneVerified == true) {
			this.setState({
				isReadOnly: true
			})
		}
	}
	renderField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder, disabled }) => {
		const { formatMessage } = this.props.intl;
		return (
			<Form.Group className={cx(s.formGroup, rs.displayGridPrifile, rs.alignItemCenter, 'textRightRTL')}>
				<div>
					<Form.Label className={rs.storeLabelText}>{label}</Form.Label>
				</div>
				<div>
					<Form.Control {...input} placeholder={placeholder} type={type} className={fieldClass} disabled={disabled} />
					{touched && error && <span className={rs.errorMessage}>{formatMessage(error)}</span>}
				</div>
			</Form.Group>
		)
	};

	renderFieldPhoneNumber = ({ input, label, writeOnce, type, meta: { touched, error }, fieldClass, placeholder, className }) => {
		const { formatMessage } = this.props.intl;
		return (
			<Form.Group className={cx(s.formGroup, rs.displayGridPrifile, rs.alignItemCenter, 'textRightRTL')}>
				<div>
					<label className={rs.storeLabelText}>{label}</label>
				</div>
				<div>
					<Form.Control {...input} readOnly={writeOnce} placeholder={placeholder} type={type} className={fieldClass} />
					{touched && error && <span className={rs.errorMessage}>{formatMessage(error)}</span>}
				</div>
			</Form.Group>
		)
	};

	renderFormControlTextArea = ({ input, label, meta: { touched, error }, children, className }) => {
		const { formatMessage } = this.props.intl
		return (
			<div>
				<FormGroup className={cx(s.formGroup, rs.displayGridPrifile, 'textRightRTL')}>
					<div>
						<label className={rs.storeLabelText}>{label}</label>
					</div>
					<div>
						<FormControl
							{...input}
							className={cx(s.inputNoRadius, className)}
							placeholder={label}
							as="textarea"
							rows="4"
						>
							{children}
						</FormControl>
						{touched && error && <span className={rs.errorMessage}>{formatMessage(error)}</span>}
					</div>
				</FormGroup>
			</div>
		);
	}

	checkboxGroup = ({ label, name, options, input, meta: { touched, error } }) => {
		const { formatMessage } = this.props.intl;
		return (
			<FormGroup className={cx(s.formGroup, rs.displayGridPrifile, 'textRightRTL')}>
				<div>
					<label className={rs.storeLabelText}>{label}</label>
				</div>
				<div>
					<ul className={s.listStyle}>
						{
							options && options.map((option, index) => {
								if (option && option.isActive) {
									return (
										<li className={s.listSectionWidth} key={index}>
											<span className={s.checkBoxSection}>
												<CustomCheckbox
													key={index}
													className={'icheckbox_square-green'}
													name={`${input.name}[${index}]`}
													value={option.id}
													checked={input.value.indexOf(option.id) !== -1}
													onChange={event => {
														const newValue = [...input.value]
														if (event === true) {
															newValue.push(option.id);
														} else {
															newValue.splice(newValue.indexOf(option.id), 1);
														}
														input.onChange(newValue);
													}
													}
												/>
											</span>
											<span className={cx(s.checkBoxLabel)}>
												{option.categoryName}
											</span>
										</li>
									)
								}
							})
						}
					</ul>
					{error && <span className={rs.errorMessage}>{formatMessage(error)}</span>}
				</div>
			</FormGroup>
		);
	}

	renderFormControlSelect = ({ input, label, meta: { touched, error }, children, className }) => {
		const { formatMessage } = this.props.intl;
		return (
			<div className={cx(s.formGroup, rs.displayGridPrifile, rs.alignItemCenter, 'textRightRTL')}>
				<div>
					<label className={rs.storeLabelText}>{label}</label>
				</div>
				<div>
					<FormControl as="select" {...input} className={className} >
						{children}
					</FormControl>
					{touched && error && <span className={rs.errorMessage}>{formatMessage(error)}</span>}
				</div>
			</div>
		)
	}


	handleLocationData(location) {
		const { change } = this.props;

		let addressLabel = location && location.label;
		let addressPosition = location && location.location;
		let locationArray = addressLabel && addressLabel.split(',').slice(-3).reverse();

		change('address', addressLabel)
		change('city', locationArray && locationArray[2] && locationArray[2].trim());
		change('state', locationArray && locationArray[1] && locationArray[1].trim());
		change('country', locationArray && locationArray[0] && locationArray[0].trim());
		change('lat', addressPosition && addressPosition.lat);
		change('lng', addressPosition && addressPosition.lng);
	};

	handleCountryChange(country) {
		const { change } = this.props;

		this.setState({
			phoneCountryCode: country.countryCode,
			phoneDialCode: country.dialCode
		});

		change('phoneCountryCode', country.countryCode)
		change('phoneDialCode', country.dialCode)
	};

	handleDropzone(fileName, fieldName) {
		const { change } = this.props
		change(fieldName, fileName)
	}

	handleDefaultPreparationTime(action) {
		const { defaultPreparationTime } = this.state;
		const { change } = this.props;

		let preparationTime = action == 'add' ? defaultPreparationTime + 5 : defaultPreparationTime - 5;

		if (preparationTime <= 55 && preparationTime >= 5) {
			this.setState({ defaultPreparationTime: preparationTime })
			change('shopEstimatedTime', preparationTime)
		}
	};

	checkboxHorizontalGroupOne = ({ label, input, checked, meta: { error } }) => {
		const { formatMessage } = this.props.intl;
		return (
			<div>
				<div className={s.listSectionWidthTwo}>
					<span className={s.checkBoxSection}>
						<CustomCheckbox
							onChange={event => {
								input.onChange(event);
							}}
							checked={checked}
							value={true}
							className={'icheckbox_square-green'}
						/>
					</span>
					<span className={cx(s.checkBoxLabel)}>
						{label}
					</span>
				</div>

				{error && <span className={cx(rs.errorMessage, s.errorPosition)}>{formatMessage(error)}</span>}
			</div>
		)
	};

	checkboxHorizontalGroupTwo = ({ label, input, checked, meta: { error } }) => {
		const { formatMessage } = this.props.intl;
		return (
			<div>
				<div className={cx(s.listSectionWidthTwo, s.listSectionWidthTwoMb)}>
					<span className={s.checkBoxSection}>
						<CustomCheckbox
							onChange={event => {
								input.onChange(event);
							}}
							checked={checked}
							value={true}
							className={'icheckbox_square-green'}
						/>
					</span>
					<span className={cx(s.checkBoxLabel)}>
						{label}
					</span>
				</div>

				{error && <span className={cx(rs.errorMessage, s.errorPosition)}>{formatMessage(error)}</span>}
			</div>
		)
	};

	handleDeleteImage() {
		const { change } = this.props;
		change('picture', null)
	}

	async loaderStart() {
		const { setLoaderStart } = this.props;
		setLoaderStart('profileImageLoader')
	}

	async loaderEnd() {
		const { setLoaderComplete } = this.props;
		setLoaderComplete('profileImageLoader')
	}

	render() {
		const { handleSubmit, loading, submitting, getCategory, initialValues, getDietary, imageLoader } = this.props;
		const { picture, phoneStatus, shopEstimatedTime, error, isDoorDelivery, isTakeAway } = this.props
		const { phoneCountryCode, phoneDialCode, isReadOnly, defaultPreparationTime } = this.state;
		const { formatMessage } = this.props.intl;
		let phoneData = {
			phoneCountryCode: phoneCountryCode,
			phoneDialCode: phoneDialCode,
			phoneNumber: initialValues ? initialValues.phoneNumber : '',
			phoneStatus: phoneStatus,
			userId: initialValues ? initialValues.id : ''
		};

		let demoRestrict = false;

		if (initialValues.id === users.store.id) demoRestrict = true;


		return (
			<div className={cx('mainContainer')}>
				<div className={cx(s.editProfileContainer)}>
					<Container fluid>
						<Row>
							<Col md={12} lg={12} sm={12} xs={12}>
								<Form className={s.fullWidth} onSubmit={handleSubmit(submit)}>
									<div>
										<h1 className={cx(rs.storeTitleText, 'textRightRTL')}>{formatMessage(messages.editProfile)}</h1>
									</div>
									<Row>
										<Col lg={12} md={12} sm={12} xs={12}>
											<Form.Group className={s.formGroup}>
												<div>
													<Field
														name="shopName"
														type="text"
														placeholder={formatMessage(messages.shopName)}
														component={this.renderField}
														label={formatMessage(messages.shopName)}
														labelClass={s.labelText}
														fieldClass={rs.formControlInputStore}
													/>
												</div>
											</Form.Group>
											<Form.Group className={s.formGroup}>
												<div>
													<Field
														name="description"
														type="text"
														placeholder={formatMessage(messages.storeDescription)}
														component={this.renderFormControlTextArea}
														label={formatMessage(messages.storeDescription)}
														labelClass={s.labelText}
														fieldClass={rs.formControlInputStore}
													/>
												</div>
											</Form.Group>
											<Form.Group className={cx(s.formGroup, rs.displayGridPrifile, 'textRightRTL')}>
												<div>
													<label className={rs.storeLabelText}>{formatMessage(messages.storeImage)}</label>
												</div>
												<div className={cx(rs.profileImgWidth, rs.storeProfileImgSection, s.droupZoneGrid, 'editLoaderZindex')}>
													<div className={s.positionRelative}>
														<Loader
															show={imageLoader}
															type={"page"}
														>
															{
																picture ?
																	<div className={cx(rs.storeBackgroundImg, s.editZoneImgWidth)} style={{ backgroundImage: `url(${api.apiEndpoint + storeImageUploadDir + picture})` }} />
																	:
																	<div className={cx(rs.storeBackgroundImg, s.editZoneImgWidth)} style={{ backgroundImage: `url(${NoLogo})` }}></div>
															}
															{
																picture ?
																	<Button onClick={this.handleDeleteImage} className={cx(s.btnTrash, 'btnTrashRTL')}>
																		<img src={TrashIcon} alt='Delete' />
																	</Button>
																	:
																	''
															}
														</Loader>

													</div>
													<div className={'retaurantDroupZone'}>
														<ImageUploadComponent
															className={cx(rs.profileButton)}
															subTextClass={s.subText}
															subText={formatMessage(messages.maximumUploadSizeLabel)}
															defaultMessage={formatMessage(messages.chooseFile)}
															inputContainer={'.dzInputContainerStoreImage'}
															inputContainerClass={'dzInputContainerStoreImage'}
															postUrl={api.apiEndpoint + '/uploadStoreImage'}
															fieldName={'picture'}
															loader={imageLoader}
															loaderName={'profileImageLoader'}
															formName={'EditProfileForm'}
															isExistDefault={true}
															isRestrict={demoRestrict}
														/>
													</div>
												</div>
											</Form.Group>
											<Form.Group className={s.formGroup}>
												<div>
													<Field
														name="firstName"
														type="text"
														placeholder={formatMessage(messages.firstName)}
														component={this.renderField}
														label={formatMessage(messages.firstName)}
														labelClass={s.labelText}
														fieldClass={rs.formControlInputStore}
													/>
												</div>
											</Form.Group>
											<Form.Group className={s.formGroup}>
												<div>
													<Field
														name="lastName"
														type="text"
														placeholder={formatMessage(messages.lastName)}
														component={this.renderField}
														label={formatMessage(messages.lastName)}
														labelClass={s.labelText}
														fieldClass={rs.formControlInputStore}
													/>
												</div>
											</Form.Group>
											<Form.Group className={s.formGroup}>
												<div>
													<Field
														name="email"
														type="text"
														placeholder={formatMessage(messages.email)}
														component={this.renderField}
														label={formatMessage(messages.email)}
														labelClass={s.labelText}
														fieldClass={rs.formControlInputStore}
														disabled
													/>
												</div>
											</Form.Group>
											<Form.Group className={cx(s.formGroup, rs.displayGridPrifile, 'textRightRTL')}>
												<div>
													<label className={rs.storeLabelText}>{formatMessage(messages.phoneNumber)}</label>
												</div>
												<div>
													<PhoneNumberVerification phoneData={phoneData} demoRestrict={demoRestrict}
													/>
												</div>
											</Form.Group>
											<Form.Group className={cx(s.formGroup, rs.displayGridPrifile, rs.alignItemCenter, 'textRightRTL')}>
												<div >
													<label className={rs.storeLabelText}>{formatMessage(messages.address)}</label>
												</div>
												<div className={'editProfile'}>
													<PlacesSuggest
														label={formatMessage(messages.address)}
														className={s.formGroup}
														handleData={this.handleLocationData}
														defaultValue={initialValues && initialValues.address}
													/>
													{error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
												</div>
											</Form.Group>
											<Form.Group className={s.formGroup}>
												<div>
													<Field
														name="zipcode"
														type="text"
														placeholder={formatMessage(messages.zipcode)}
														component={this.renderField}
														label={formatMessage(messages.zipcode)}
														labelClass={s.labelText}
														fieldClass={rs.formControlInputStore}
													/>
												</div>
											</Form.Group>
											{/* <Form.Group className={s.formGroup}>
												<Field name="priceRange"
													className={cx(rs.formControlSelect, rs.formControlInputStore)}
													component={this.renderFormControlSelect}
													placeholder={formatMessage(messages.priceRange)}
													label={formatMessage(messages.priceRange)}
												>
													<option value="">{formatMessage(messages.choosePriceRange)}</option>
													<option value={1}>$</option>
													<option value={2}>$$</option>
													<option value={3}>$$$</option>
													<option value={4}>$$$$</option>
												</Field>
											</Form.Group> */}

											<div className={cx(s.formGroup, rs.displayGridPrifile, 'textRightRTL')}>
												<label className={rs.restaurantLabelText}>{formatMessage(messages.DeliveryTypeText)}</label>
												<div className={cx(s.deliveryGrid, s.deliveryLine, 'textRightRTL')}>
													<Form.Group className={s.noMargin}>
														<Field name="isDoorDelivery"
															component={this.checkboxHorizontalGroupTwo}
															label={formatMessage(messages.isDoorDelivery)}
															checked={isDoorDelivery}

														/>
													</Form.Group>
													<Form.Group className={s.noMargin}>
														<Field name="isTakeAway"
															component={this.checkboxHorizontalGroupOne}
															label={formatMessage(messages.isTakeAway)}
															checked={isTakeAway}
														/>
													</Form.Group>
												</div>
											</div>
											<Form.Group className={s.formGroup}>
												<Field name="categoryType"
													component={this.checkboxGroup}
													options={getCategory && getCategory.result}
													label={formatMessage(messages.category)}
												/>
											</Form.Group>
										</Col>
										<Col lg={12} md={12} sm={12} xs={12} className={cx(rs.alignRightText, rs.spaceTop3, 'textLeftRTL')}>
											<Form.Group className={s.formGroup}>
												<div className={s.displayInlineSection}>
													<Loader
														type={"button"}
														label={formatMessage(messages.update)}
														show={loading}
														buttonType={'submit'}
														className={cx(rs.button, rs.btnPrimary)}
														disabled={submitting || loading}
														isSuffix={true}
													/>
												</div>
											</Form.Group>
										</Col>
									</Row>
								</Form>
							</Col>
						</Row>
					</Container>
				</div>
			</div>
		)
	}
}

EditProfileForm = reduxForm({
	form: 'EditProfileForm',
	onSubmit: submit,
	validate
})(EditProfileForm);

const selector = formValueSelector('EditProfileForm');

const mapState = state => ({
	picture: selector(state, 'picture'),
	loading: state.loader.EditProfile,
	phoneNumber: selector(state, 'phoneNumber'),
	isPhoneVerified: selector(state, 'isPhoneVerified'),
	availableCurrencies: state.currency.availableCurrencies,
	imageLoader: state.loader.profileImageLoader,
	isDoorDelivery: selector(state, 'isDoorDelivery'),
	isTakeAway: selector(state, 'isTakeAway'),
});

const mapDispatch = {
	change,
	setLoaderStart,
	setLoaderComplete,
};


export default injectIntl(withStyles(s, rs)(connect(mapState, mapDispatch)(EditProfileForm)));