import { editCategory } from '../../../../actions/siteadmin/Category/editCategory';
import { manageCategory } from '../../../../demo';
import { demoRestriction } from '../../../../actions/siteadmin/demoRestriction';

async function submit(values, dispatch) {
    if (manageCategory.indexOf(values.id) > -1) {
        const isRestrict = await dispatch(demoRestriction());
        if (isRestrict) return;
    }
    await dispatch(
        editCategory(
            values.id,
            values.categoryName,
            values.isActive,
            values.description
        )
    )
}

export default submit;