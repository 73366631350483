import { editRider } from '../../../actions/siteadmin/Users/editRider';
import { demoRestriction } from '../../../actions/siteadmin/demoRestriction';

async function submit(values, dispatch) {
    const isRestrict = await dispatch(demoRestriction());
    if (isRestrict) return;
    await dispatch(
        editRider(
            {
                id: values.id,
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                password: values.password ? values.password : undefined,
                phoneDialCode: values.phoneDialCode,
                phoneNumber: values.phoneNumber,
                userStatus: values.userStatus,
                isBan: values.isBan,
                phoneCountryCode: values.phoneCountryCode
            }
        )
    )

}

export default submit;