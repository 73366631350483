import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';

import Header from '../Header';
import Footer from '../Footer';
import SocketContext from '../SocketContext';
import CookiesDisclaimer from '../CookiesDisclaimer';
import DemoRestrictionModal from '../DemoRestriction/DemoRestrictionModal';


import normalizeCss from 'normalize.css';
import s from './Layout.css';

class Layout extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  render() {
    const { homeHeader } = this.props;
    return (
      <SocketContext.Consumer>
        {
          socket => (
            <div className={homeHeader}>
              <DemoRestrictionModal />
              <Header socket={socket} />
              {this.props.children}
              <Footer />
              <CookiesDisclaimer />
            </div>
          )
        }
      </SocketContext.Consumer>
    );
  }
}

export default withStyles(normalizeCss, s)(Layout);