import showToaster from '../../../../helpers/showToaster';
import { addUpdateDeliveryVehicle } from '../../../../actions/siteadmin/DeliveryVehicle/addUpdateDeliveryVehicle';
import { manageVehicleTypes } from '../../../../demo';
import { demoRestriction } from '../../../../actions/siteadmin/demoRestriction';

async function submit(values, dispatch) {

    if (manageVehicleTypes.indexOf(values.id) > -1) {
        const isRestrict = await dispatch(demoRestriction());
        if (isRestrict) return;
    }
    
    if (!values?.vehicleImage) {
        showToaster({ messageId: 'vehicleImageError', toasterType: 'error' })
        return;
    }

    await dispatch(
        addUpdateDeliveryVehicle(
            {
                id: values?.id,
                vehicleName: values?.vehicleName,
                vehicleImage: values?.vehicleImage,
                description: values?.description,
                isActive: values?.isActive
            }
        )
    );
}

export default submit;