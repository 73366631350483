import showToaster from '../../../helpers/showToaster';
import { getAllAdminUsers as query, createAdminUserMutation, getAdminUserQuery, getPrivilegesQuery } from '../../../lib/graphql';
import { setRuntimeVariable } from '../../runtime';
import { closeAdminUserModal } from '../modalActions';
import {
    CREATE_ADMIN_USER_START,
    CREATE_ADMIN_USER_SUCCESS,
    CREATE_ADMIN_USER_ERROR,
    GET_ADMIN_USER_START,
    GET_ADMIN_USER_SUCCESS,
    GET_ADMIN_USER_ERROR
} from '../../../constants';
import { decode } from '../../../helpers/queryEncryption';

const createAdminUser = (id, email, password, roleId) => {
    return async (dispatch, getState, { client }) => {

        try {
            let requestContent;

            await dispatch({
                type: CREATE_ADMIN_USER_START,
                payload: {
                    createAdminUserLoading: true
                }
            });

            const { data } = await client.mutate({
                mutation: createAdminUserMutation,
                variables: {
                    id,
                    email,
                    password,
                    roleId
                },
                refetchQueries: [{ query }]
            });

            requestContent = id ? 'updated' : 'added';

            if (data?.createAdminUser?.status === 200) {
                await dispatch({
                    type: CREATE_ADMIN_USER_SUCCESS,
                    payload: {
                        createAdminUserLoading: false
                    }
                });
                dispatch(closeAdminUserModal());
                showToaster({ messageId: 'createAdminUser', toasterType: 'success', requestContent })
                return {
                    status: 200
                };
            } else {
                showToaster({ messageId: 'commonError', toasterType: 'error', requestContent: data?.createAdminUser?.errorMessage })
                await dispatch({
                    type: CREATE_ADMIN_USER_ERROR,
                    payload: {
                        createAdminUserLoading: false,
                        error: data?.createAdminUser?.errorMessage
                    }
                });
                return {
                    status: 400
                };
            }

        } catch (error) {
            await dispatch({
                type: CREATE_ADMIN_USER_ERROR,
                payload: {
                    createAdminUserLoading: false,
                    error
                }
            });
            return {
                status: 400
            };
        }
    }
};

const getAdminUser = () => {
    return async (dispatch, getState, { client }) => {

        try {
            let adminPrivileges, privileges, defaultPrivileges;

            const privilegesData = await client.query({
                query: getPrivilegesQuery
            });

            privileges = privilegesData?.data?.getPrivileges?.results;

            defaultPrivileges = privileges?.length > 0 && privileges?.map((item) => item?.id);

            await dispatch({
                type: GET_ADMIN_USER_START,
                payload: {
                    getAdminUserLoading: true
                }
            });

            const { data } = await client.query({
                query: getAdminUserQuery
            });

            if (data?.getAdminUser?.id) {
                dispatch(setRuntimeVariable({
                    name: 'isSuperAdmin',
                    value: data?.getAdminUser?.isSuperAdmin
                }));

                adminPrivileges = {
                    id: data?.getAdminUser?.id,
                    email: decode(data.getAdminUser.email),
                    isSuperAdmin: data?.getAdminUser?.isSuperAdmin,
                    roleId: data?.getAdminUser?.roleId,
                    privileges: (data?.getAdminUser?.adminRole?.privileges) || []
                };

                if (adminPrivileges?.isSuperAdmin) {
                    adminPrivileges['privileges'] = defaultPrivileges;
                }

                await dispatch({
                    type: GET_ADMIN_USER_SUCCESS,
                    payload: {
                        getAdminUserLoading: false,
                        adminPrivileges
                    }
                });

                return adminPrivileges;
            } else {
                await dispatch({
                    type: GET_ADMIN_USER_SUCCESS,
                    payload: {
                        getAdminUserLoading: false,
                        error: data?.getAdminUser?.errorMessage
                    }
                });
                return false;
            }

        } catch (error) {
            await dispatch({
                type: GET_ADMIN_USER_ERROR,
                payload: {
                    getAdminUserLoading: false,
                    error
                }
            });
            return false;
        }
    }
};

export {
    createAdminUser, getAdminUser
};