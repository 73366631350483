import showToaster from '../../../helpers/showToaster';
import { loadAccount } from '../userAccount/userAccount';
import { demoRestriction } from '../../../demo';
import demoRestrictionToaster from '../../../helpers/demoRestrictionToaster';
import { getAllSubMenu as query, deleteSubMenu as mutation } from '../../../lib/graphql';

const deleteSubMenu = (id, currentPage, menuId) => {
    return async (dispatch, getState, { client }) => {

        try {

            if (demoRestriction.manageSubMenu && demoRestriction.manageSubMenu.indexOf(id) >= 0) {
                demoRestrictionToaster();
                return;
            }

            const { data } = await client.mutate({
                mutation,
                variables: {
                    id
                },
                refetchQueries: [{
                    query,
                    variables: {
                        currentPage,
                        searchList: '',
                        menuId
                    }
                }]
            });

            if (data?.deleteSubMenu?.status === 200) {
                showToaster({ messageId: 'deleteSubMenu', toasterType: 'success' });
                dispatch(loadAccount());
            } else {
                showToaster({ messageId: 'commonError', toasterType: 'error', requestContent: data?.deleteSubMenu?.errorMessage });
            }
        } catch (error) {
            showToaster({ messageId: 'catchError', toasterType: 'error', requestContent: error });
        }
    }
};

export default deleteSubMenu;