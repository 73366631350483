import addSubMenu from '../../../../../actions/shop/menu/addSubMenu';
import { demoRestriction } from '../../../../../demo';
import demoRestrictionToaster from '../../../../../helpers/demoRestrictionToaster';
import { closeSubMenuModal } from '../../../../../actions/siteadmin/modalActions';

async function submit(values, dispatch) {
     if (demoRestriction.manageSubMenu && demoRestriction.manageSubMenu.indexOf(values.id) >= 0) {
          demoRestrictionToaster();
          dispatch(closeSubMenuModal());
          return;
     }

     await dispatch(addSubMenu(values));
}

export default submit;