import gql from 'graphql-tag';
import { toastr } from 'react-redux-toastr';
import {
    UPDATE_SITE_SETTINGS_START,
    UPDATE_SITE_SETTINGS_ERROR,
    UPDATE_SITE_SETTINGS_SUCCESS
} from '../../constants/index';

export default function updateSiteSettingsDropDown(fieldName, value) {

    return async (dispatch, getState, { client }) => {

        dispatch({
            type: UPDATE_SITE_SETTINGS_START
        })

        try {
            const mutation = gql`
            mutation updateSiteSettingsDropDown(
                $fieldName: String,
                $value: String,
                ) {
                    updateSiteSettingsDropDown(
                        fieldName: $fieldName
                        value: $value
                    ){
                        status
                        errorMessage
                    }
                }
            `;

            const { data } = await client.mutate({
                mutation,
                variables: {
                    fieldName,
                    value
                }
            })

            if (data && data.updateSiteSettingsDropDown && data.updateSiteSettingsDropDown.status == 200) {

                dispatch({
                    type: UPDATE_SITE_SETTINGS_SUCCESS
                })
                // await dispatch(siteSettings())
                toastr.success('Success', 'Site settings changes have been successfully applied')
            } else {
                dispatch({
                    type: UPDATE_SITE_SETTINGS_ERROR
                })
                toastr.error('Oops!', 'Something went wrong')
            }
        } catch (err) {
            dispatch({
                type: UPDATE_SITE_SETTINGS_ERROR
            })
            toastr.error('Oops!', 'Something went wrong')
        }

    }
}