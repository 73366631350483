import React, { Component } from 'react';
import DropzoneComponent from 'react-dropzone-component';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from '!isomorphic-style-loader!css-loader!./filepicker.css';
import cx from 'classnames';
import showToaster from '../../../helpers/showToaster';
import { api } from '../../../config';
import { demoRestriction } from '../../../actions/siteadmin/demoRestriction';
import demoRestrictionToaster from '../../../helpers/demoRestrictionToaster';

export class Dropzone extends Component {

	constructor(props) {
		super(props)

		this.addedfile = this.addedfile.bind(this);
		this.success = this.success.bind(this);
		this.dropzone = null;
	}

	componentDidUpdate() {
		const isBrowser = typeof window !== 'undefined';
		const isDocument = typeof document !== undefined;
		if (isBrowser && isDocument) {
			document.querySelector(".dz-hidden-input").style.visibility = 'visible';
			document.querySelector(".dz-hidden-input").style.opacity = '0';
			document.querySelector(".dz-hidden-input").style.height = '100%';
			document.querySelector(".dz-hidden-input").style.width = '100%';
			document.querySelector(".dz-hidden-input").style.cursor = 'pointer';
		}
	}

	async success(file, fromServer) {
		const { handleDropzone, fieldName, loaderEnd, isLoader, index, demoRestrict } = this.props;
		const fileName = fromServer.fileName;
		if (!demoRestrict) handleDropzone(fileName, fieldName)
		if (isLoader) loaderEnd(index);
	}

	async addedfile(file, fromServer) {
		const { loaderEnd, isLoader, loaderStart, index, isAdmin, demoRestrict, maxUploadSize, demoRestriction } = this.props;
		let isOnline = typeof window !== 'undefined' && window.navigator.onLine;

		let fileTypes = ['image/jpeg', 'image/png', 'image/svg+xml', 'image/jpg']
		if (!fileTypes.includes(file.type)) {
			showToaster({ messageId: 'fileTypeError', toasterType: 'error' })
			if (isLoader) await loaderEnd(index);
		}

		let fileFormates = [
			'application/sql',
			'application/pdf',
			'application/vnd.oasis.opendocument.presentation',
			'text/csv',
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			'application/epub+zip',
			'application/zip',
			'text/plain',
			'application/rtf',
			'application/vnd.oasis.opendocument.text',
			'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
			'application/vnd.oasis.opendocument.spreadsheet',
			'text/tab-separated-values',
			'text/calendar',
			'video/webm',
			'image/svg+xml'
		];

		if (isAdmin) {
			const isRestrict = await demoRestriction();
			if (isRestrict) {
				this.dropzone.removeFile(file);
				return;
			}
		} else if (demoRestrict) {
			this.dropzone.removeFile(file.name);
			demoRestrictionToaster();
			return;
		}


		if (file.accepted == false) {
			setTimeout(async () => {
				if (file.accepted == false) {
					showToaster({ messageId: 'fileTypeError', toasterType: 'error' })
					this.dropzone.removeFile(file.name);
					if (isLoader) await loaderEnd(index);
				}
			}, 1000)
		}

			
		if(!isOnline) {
			showToaster({ messageId: 'networkFailed', toasterType: 'error' })
			this.dropzone.removeFile(file);
            if (isLoader) setLoaderComplete(loaderName);
			return;
		}

		if (file && file.size > (1024 * 1024 * parseInt(maxUploadSize))) {
			showToaster({ messageId: 'limitError', toasterType: 'error' })
			this.dropzone.removeFile(file);
			if (isLoader) await loaderEnd();
		} else {
			if (isLoader) loaderStart(index);
		}

		if (fileFormates.indexOf(file && file.type) > 0) {

			setTimeout(async () => {
				if (file && file.accepted === false) {
					showToaster({ messageId: 'fileTypeError', toasterType: 'error' })
					this.dropzone.removeFile(file.name);
					if (isLoader) await loaderEnd(index);
				}
			}, 1000)
		}


		if (file && file.accepted === true) {
			setTimeout(() => {
				if (file && file.accepted === true) {

				}
			}, 1000)
		}
	}

	render() {
		const { defaultMessage, className, subTextClass, subText, inputContainer, inputContainerClass, url, maxUploadSize } = this.props;
		const apiEndpoint = api && api.apiEndpoint;

		const djsConfig = {
			dictDefaultMessage: '',
			addRemoveLinks: false,
			uploadMultiple: false,
			maxFilesize: parseInt(maxUploadSize),
			acceptedFiles: 'image/jpeg,image/png,image/jpg',
			dictMaxFilesExceeded: 'Remove the existing image and try upload again',
			previewsContainer: false,
			hiddenInputContainer: inputContainer,
			timeout: 300000
		};

		var componentConfig = {
			iconFiletypes: ['.jpg', '.png'],
			multiple: false,
			showFiletypeIcon: false,
			postUrl: apiEndpoint + url
		};

		const eventHandlers = {
			init: dz => this.dropzone = dz,
			success: this.success,
			addedfile: this.addedfile
		};


		return (
			<div className={cx('listPhotoContainer')}>
				<div className={cx(inputContainerClass)}>
					<div className={className}>
						<DropzoneComponent
							config={componentConfig}
							eventHandlers={eventHandlers}
							djsConfig={djsConfig}
						>
							{defaultMessage}
						</DropzoneComponent>
					</div>
				</div>
				<p className={cx(subTextClass, 'droupText')}>
					{subText}: {maxUploadSize}MB
				</p>
			</div>

		)
	}
}

const mapState = state => ({
	maxUploadSize: state.siteSettings.data.maxUploadSize
});

const mapDispatch = {
	demoRestriction
};

export default withStyles(s)(connect(mapState, mapDispatch)(Dropzone));
