import updateShop from '../../../../actions/siteadmin/ManageShop/updateShop';
import { demoRestriction } from '../../../../actions/siteadmin/demoRestriction';

async function submit(values, dispatch) {
    const isRestrict = await dispatch(demoRestriction());
    if (isRestrict) return;
    await dispatch(
        updateShop(values)
    )
}

export default submit;