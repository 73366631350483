import { updateStaticPage } from '../../../actions/siteadmin/updateStaticPage';
import { demoRestriction } from '../../../actions/siteadmin/demoRestriction';
import showToaster from '../../../helpers/showToaster';

async function submit(values, dispatch) {
    if (values.content == null || values.content == '<p><br></p>' || values.content == '<p> </p>') {
        showToaster({ messageId: 'addContent', toasterType: 'error' })
    } else {
        const isRestrict = await dispatch(demoRestriction());
        if (isRestrict) return;
        await dispatch(updateStaticPage(values));
    }
}

export default submit;