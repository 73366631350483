import updateSiteSettings from '../../../actions/siteadmin/updateSiteSettings';
import { demoRestriction } from '../../../actions/siteadmin/demoRestriction';
import showToaster from '../../../helpers/showToaster';

const submit = async (values, dispatch) => {

    const isRestrict = await dispatch(demoRestriction());
    if (isRestrict) return;

    if (!values.ogImage) {
        return showToaster({ messageId: 'ogImageError', toasterType: 'error' })
    }
    await dispatch(
        updateSiteSettings(
            {
                siteName: values?.siteName,
                siteTitle: values?.siteTitle,
                metaDescription: values?.metaDescription,
                facebookLink: values?.facebookLink,
                twitterLink: values?.twitterLink,
                instagramLink: values?.instagramLink,
                metaKeyword: values?.metaKeyword,
                homeLogo: values?.homeLogo,
                youtubeLink: values?.youtubeLink,
                currency: values?.currency,
                distanceUnits: values?.distanceUnits,
                appForceUpdate: values?.appForceUpdate,
                userAndroidVersion: values?.userAndroidVersion,
                userIosVersion: values?.userIosVersion,
                driverAndroidVersion: values?.driverAndroidVersion,
                driverIosVersion: values?.driverIosVersion,
                preferredDelivery: values?.preferredDelivery,
                modifiers: values?.modifiers,
                favicon: values?.favicon,
                contactNumber: values?.contactNumber,
                contactEmail: values?.contactEmail,
                contactSkype: values?.contactSkype,
                notificationInterval: values?.notificationInterval,
                orderAcceptInterval: values?.orderAcceptInterval,
                requestTimeTone: values?.requestTimeTone,
                requestToneFile: values?.requestToneFile,
                ogImage: values?.ogImage
            }
        )
    )
};

export default submit;