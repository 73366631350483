import React from 'react';
import AdminLayout from '../../../components/Layout/AdminLayout'
import { restrictUrls } from '../../../helpers/adminPrivileges';
import messages from '../../../locale/messages';
import ConfigSettings from './ConfigSettings';

function action({ store, intl }) {

    const title = intl.formatMessage(messages.manageSiteConfig);
    let isAdminAuthenticated = store.getState().runtime.isAdminAuthenticated;
    let adminPrivileges = store.getState().account.privileges && store.getState().account.privileges.privileges;
    let privileges = store.getState().commonSettings && store.getState().commonSettings.privileges;
    let adminId = store.getState().account && store.getState().account.privileges && store.getState().account.privileges.id;

    if (!isAdminAuthenticated) {
        return { redirect: '/siteadmin/login' }
    }
    // Admin restriction
    if (!restrictUrls('/siteadmin/settings/config', adminPrivileges, privileges)) {
        return { redirect: '/siteadmin' };
    }
    return {
        title,
        component: (
            <AdminLayout>
                <ConfigSettings title={title} adminId={adminId} />
            </AdminLayout>
        )
    }
}
export default action;