import React, { Component } from 'react'
import { connect } from 'react-redux'
import withStyles from 'isomorphic-style-loader/withStyles';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag'
import { flowRight as compose } from 'lodash'
import cx from 'classnames';
import { injectIntl, FormattedMessage } from 'react-intl';
import Row from 'react-bootstrap/Row';
import FormGroup from 'react-bootstrap/FormGroup';
import Col from 'react-bootstrap/Col';
import FormControl from 'react-bootstrap/FormControl';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import InputGroup from 'react-bootstrap/InputGroup';

import Loader from '../../Common/Loader';
import SwitchButton from './Switch';
import updateSiteSettingsDropDown from '../../../actions/siteadmin/updateSiteSettingsDropDown';
import TripToneDropzone from './ToneDropZone';
import ImageUploadComponent from '../../ImageUploadComponent/ImageUploadComponent';

import submit from './submit'
import validate from './validate'
import messages from '../../../locale/messages'
import { siteSettings } from '../../../actions/siteadmin/siteSettings';
import { deleteTone } from '../../../actions/siteadmin/updateSiteSettings';
import { api, logoUploadDir, faviconUploadDir, ogImageUploadDir, siteUrl, toneUploadDir } from '../../../config'
import { updateTempImages } from '../../../actions/siteadmin/TempImages/updateTempImages';

import pauseIcon from '../../../../public/Icons/tonePause.svg';
import playIcon from '../../../../public/Icons/tonePlay.svg';
import defaultImage from '../../../../public/defaultimage.svg';
import deleteIcon from '../../../../public/Icons/toneClose.svg';

import s from './SiteSettingsForm.css';

export class SiteSettingsForm extends Component {

  static defaultProps = {
    loading: false,
    updateSiteSettings: false
  };

  constructor(props) {
    super(props);
    this.state = {
      play: false
    };
    this.handleChange = this.handleChange.bind(this);
  }

  async handleChange(e, fieldName) {
    const { updateSiteSettingsDropDown } = this.props;
    await updateSiteSettingsDropDown(fieldName, e.target.value);
  }

  togglePlay = () => {
    const audioEl = document.getElementById("audio-element");
    if (audioEl) {
      this.setState({ play: !this.state.play }, () => {
        this.state.play ? audioEl.play() : audioEl.pause();
      });
    }
  }


  renderFormControl = ({ input, label, type, meta: { touched, error }, className, maxlength }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <FormGroup className={s.space3}>
          <div>
            <label className={s.labelText} >{label}</label>
          </div>
          <div>
            <FormControl {...input} placeholder={label} type={type} className={cx(className, s.formControlInput)} maxlength={maxlength} />
            {touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
          </div>
        </FormGroup>
      </div>
    );
  }

  renderAddonFormControl = ({ input, label, type, meta: { touched, error }, className, maxlength, addonLabel }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div className={cx('inputFormAddon', 'addonBorder', 'addonRightBorder')}>
        <Form.Group>
          <label className={s.labelText} >{label}</label>
          <InputGroup>
            <FormControl {...input} placeholder={label} type={type} className={s.formControlInput} maxlength={maxlength} />
            <InputGroup.Text>{addonLabel}</InputGroup.Text>
          </InputGroup>
          {touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
        </Form.Group>
      </div>
    );
  }

  renderFormControlTextArea = ({ input, label, meta: { touched, error }, children, className }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <FormGroup className={s.space3}>
          <div>
            <label className={s.labelText} >{label}</label>
          </div>
          <div>
            <FormControl
              {...input}
              className={className}
              placeholder={label}
              as="textarea"
              rows='4'
            >
              {children}
            </FormControl>
            {touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
          </div>
        </FormGroup>
      </div>
    );
  }

  renderSelectField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder, children }) => {
    return (
      <Form.Group className={s.space3}>
        <Form.Label>{label}</Form.Label>
        <Form.Control as="select" {...input} placeholder={placeholder} className={fieldClass}>
          {children}
        </Form.Control>
        {touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{error.defaultMessage}</span>}
      </Form.Group>
    )
  }
  renderFieldApp = ({ input, label, type, meta: { touched, error }, className, maxlength }) => {
    return (
      <div className={cx('inputFormAddon', 'addonBorder', 'addonLeftBorder')}>
        <Form.Group>
          <label className={s.labelText} >{label}</label>
          <InputGroup>
            <InputGroup.Append>
              <InputGroup.Text>
                V
              </InputGroup.Text>
            </InputGroup.Append>
            <FormControl {...input} placeholder={label} type={type} className={s.formControlInput} maxlength={maxlength} />
          </InputGroup>
          {touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{error.defaultMessage}</span>}
        </Form.Group>
      </div>
    )
  }

  renderSwitch = ({ input, meta: { touched, error }, switchOnLabel, switchOffLabel }) => {
    const { formatMessage } = this.props.intl;
    input.value = input.value == "0" ? false : true;

    return (
      <div>
        <SwitchButton
          {...input}
          defaultValue={input.value}
          switchOnLabel={switchOnLabel}
          switchOffLabel={switchOffLabel}
        />
      </div>
    )
  }

  handleDropzone = async (fileName, fieldName) => {
    const { change, updateTempImages } = this.props;
    await change('favicon', fileName);
    await updateTempImages('SiteSettings', 'favicon', fileName);
  }

  render() {
    const { formatMessage } = this.props.intl;
    const { handleSubmit, logo, loading, submitting, updateSiteSettings, appForceUpdate, favicon, deleteTone, requestTone, requestToneFile, ogImage } = this.props;
    const { logoImageLoader, favImageLoader, ogImageLoader } = this.props;
    const { data: { getCurrencies } } = this.props;
    let logoImage = logo ? api.apiEndpoint + logoUploadDir + 'medium_' + logo : defaultImage;

    return (
      <div className={s.paddignLeftRight}>
        <Container fluid>
          <form onSubmit={handleSubmit(submit)}>
            <Row className='siteSettingFormRow'>
              <Col xs={12} sm={12} md={12} lg={6} xl={4} className={cx(s.marbtm14, s.spaceTop2)}>
                <div className={s.profileImgSection}>
                  <div>
                    <ImageUploadComponent
                      className={cx(s.btnSecondary, s.profileNoPadding)}
                      subTextClass={s.subText}
                      subText={formatMessage(messages.maximumUploadSizeLabel)}
                      defaultMessage={formatMessage(messages.chooseFile)}
                      loaderName={'logoImageLoader'}
                      postUrl={api.apiEndpoint + '/uploadLogoImage'}
                      loader={logoImageLoader}
                      fieldName={'homeLogo'}
                      formName={'SiteSettingsForm'}
                      imageSrc={logo ? api.apiEndpoint + logoUploadDir + logo : null}
                      label={formatMessage(messages.logo)}
                      inputContainer={'.dzInputContainerLogo'}
                      inputContainerClass={cx('dzInputContainerLogo', 'dzInputContainerLogoOpacity')}
                      isDemoRestriction
                    />
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6} xl={4} className={cx(s.marbtm14, s.spaceTop2)}>
                <div className={s.profileImgSection}>
                  <div className={'commonFilepicker'}>
                    <ImageUploadComponent
                      className={cx(s.btnSecondary, 'fileNoPadding')}
                      subTextClass={s.subText}
                      subText={formatMessage(messages.maximumUploadSizeLabel)}
                      defaultMessage={formatMessage(messages.chooseFile)}
                      loaderName={'favImageLoader'}
                      postUrl={siteUrl + '/uploadFavicon'}
                      loader={favImageLoader}
                      fieldName={'favicon'}
                      formName={'SiteSettingsForm'}
                      imageSrc={favicon ? siteUrl + faviconUploadDir + favicon : null}
                      label={formatMessage(messages.favIconlogoLabel)}
                      inputContainer={'.dzInputContainerLogo'}
                      inputContainerClass={cx('dzInputContainerLogo', 'dzInputContainerLogoOpacity')}
                      handleDropzone={this.handleDropzone}
                      isDemoRestriction
                    />

                  </div>
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6} xl={4} className={cx(s.marbtm14, s.spaceTop2)}>
                <div className={s.profileImgSection}>
                  <div className={'commonFilepicker'}>
                    <ImageUploadComponent
                      className={cx(s.btnSecondary, 'fileNoPadding')}
                      subTextClass={s.subText}
                      subText={formatMessage(messages.maximumUploadSizeLabel)}
                      defaultMessage={formatMessage(messages.chooseFile)}
                      loaderName={'ogImageLoader'}
                      postUrl={api.apiEndpoint + '/uploadOgImage'}
                      loader={ogImageLoader}
                      fieldName={'ogImage'}
                      formName={'SiteSettingsForm'}
                      imageSrc={ogImage ? api.apiEndpoint + ogImageUploadDir + ogImage : null}
                      label={formatMessage(messages.ogImageLabel)}
                      toolTip={formatMessage(messages.ogImageTooltip)}
                      isDemoRestriction
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={12} lg={6}>
                <Field name="siteName" type="text" component={this.renderFormControl} label={formatMessage(messages.siteNameLabel)} />
              </Col>
              <Col xs={12} sm={12} md={12} lg={6}>
                <Field name="siteTitle" type="text" component={this.renderFormControl} label={formatMessage(messages.siteTitleLabel)} />
              </Col>
              <Col xs={12} sm={12} md={12} lg={6}>
                <Field name="metaKeyword" type="text" component={this.renderFormControlTextArea} label={formatMessage(messages.metaKeywordLabel)} />
              </Col>
              <Col xs={12} sm={12} md={12} lg={6}>
                <Field name="metaDescription" type="text" component={this.renderFormControlTextArea} label={formatMessage(messages.metaDescriptionLabel)} />
              </Col>
              <Col xs={12} sm={12} md={12} lg={6}>
                <Field name="facebookLink" type="text" component={this.renderFormControl} label={formatMessage(messages.facebookURLLabel)} />
              </Col>
              <Col xs={12} sm={12} md={12} lg={6}>
                <Field name="twitterLink" type="text" component={this.renderFormControl} label={formatMessage(messages.twitterURLLabel)} />
              </Col>
              <Col xs={12} sm={12} md={12} lg={6}>
                <Field name="instagramLink" type="text" component={this.renderFormControl} label={formatMessage(messages.instagramURLLabel)} />
              </Col>
              <Col xs={12} sm={12} md={12} lg={6}>
                <Field name="youtubeLink" type="text" component={this.renderFormControl} label={formatMessage(messages.youtubeURLLabel)} />
              </Col>
              <Col xs={12} sm={12} md={12} lg={6}>
                <Field name="contactNumber" type="text" component={this.renderFormControl} label={formatMessage(messages.contactNumber)} />
              </Col>
              <Col xs={12} sm={12} md={12} lg={6}>
                <Field name="contactEmail" type="text" component={this.renderFormControl} label={formatMessage(messages.contactEmail)} />
              </Col>
              <Col xs={12} sm={12} md={12} lg={6}>
                <Field name="contactSkype" type="text" component={this.renderFormControl} label={formatMessage(messages.contactSkype)} />
              </Col>
              <Col xs={12} sm={12} md={12} lg={6}>
                <Field name="notificationInterval" type="text" component={this.renderAddonFormControl} label={formatMessage(messages.notificationInterval)} addonLabel={formatMessage(messages.minutes)} />
              </Col>
              <Col xs={12} sm={12} md={12} lg={6}>
                <Field
                  name="currency"
                  placeholder={formatMessage(messages.currency)}
                  component={this.renderSelectField}
                  label={formatMessage(messages.currency)}
                  labelClass={s.labelText}
                  fieldClass={cx(s.formControlSelect, s.formControlInput)}
                  onChange={(e) => this.handleChange(e, 'currency')}
                >
                  <option value={""}>{formatMessage(messages.currency)}</option>
                  {
                    getCurrencies && getCurrencies.map((item, index) => {
                      return item.isEnable == 1 && <option key={index} value={item.symbol}>{item.symbol}</option>
                    })
                  }
                </Field>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6}>
                <Field
                  name="distanceUnits"
                  placeholder={formatMessage(messages.distanceUnits)}
                  component={this.renderSelectField}
                  label={formatMessage(messages.distanceUnits)}
                  labelClass={s.labelText}
                  fieldClass={cx(s.formControlSelect, s.formControlInput)}
                  onChange={(e) => this.handleChange(e, 'distanceUnits')}
                >
                  <option value="km">{formatMessage(messages.kmLabel)}</option>
                  <option value="miles">{formatMessage(messages.milesLabel)}</option>
                </Field>
              </Col>
            </Row>
            <Row>
              <Col lg={6} md={6} sm={6} xs={12}>
                <Form.Group className={s.formGroup}>
                  <div>
                    <label className={s.labelText} >{formatMessage(messages.forceUpdate)}</label><br />
                    <Field name="appForceUpdate" className={cx(s.formControlSelect, s.formControlInput)} component="select">{formatMessage(messages.forceUpdate)}
                      <option value="true">{formatMessage(messages.enable)}</option>
                      <option value="false">{formatMessage(messages.disable)}</option>
                    </Field>
                  </div>
                </Form.Group>
              </Col>
              <Col lg={6} md={6} sm={6} xs={12}>
                <Form.Group className={s.formGroup}>
                  <div>
                    <label className={s.labelText} >{formatMessage(messages.preferredDelivery)}</label><br />
                    <Field name="preferredDelivery" onChange={(e) => this.handleChange(e, 'preferredDelivery')} className={cx(s.formControlSelect, s.formControlInput)} component="select">{formatMessage(messages.preferredDelivery)}
                      <option value="1">{formatMessage(messages.meetAtDoor)}</option>
                      <option value="2">{formatMessage(messages.leaveAtDoor)}</option>
                      <option value="3">{formatMessage(messages.pickUpOutside)}</option>
                    </Field>
                  </div>
                </Form.Group>
              </Col>
            </Row>
            {appForceUpdate === 'true' && <Row>
              <Col xs={12} sm={12} md={12} lg={6} className={s.space2}>
                <Form.Group className={s.formGroup}>
                  <div>
                    <Field name="userAndroidVersion" type="text" component={this.renderFieldApp} label={formatMessage(messages.userAndriodVersion)}
                      maxlength={10} />
                  </div>
                </Form.Group>
              </Col>

              <Col xs={12} sm={12} md={12} lg={6} className={s.space2}>
                <Form.Group className={s.formGroup}>
                  <div>
                    <Field name="userIosVersion" type="text" component={this.renderFieldApp} label={formatMessage(messages.useriosVersion)} maxlength={10} />
                  </div>
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6} className={s.space2}>
                <Form.Group className={s.formGroup}>
                  <div>
                    <Field name="driverAndroidVersion" type="text" component={this.renderFieldApp} label={formatMessage(messages.driverAndriodVersion)}
                      maxlength={10} />
                  </div>
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6} className={s.space2}>
                <Form.Group className={s.formGroup}>
                  <div>
                    <Field name="driverIosVersion" type="text" component={this.renderFieldApp} label={formatMessage(messages.driveriosVersion)} maxlength={10} />
                  </div>
                </Form.Group>
              </Col>
            </Row>}

            <Row>
              <Col lg={6} md={6} sm={6} xs={12}>
                <Form.Group className={s.formGroup}>
                  <div>
                    <label className={s.labelText} >{formatMessage(messages.modifiers)}</label>
                    <Field name="modifiers" onChange={(e) => this.handleChange(e, 'modifiers')} className={cx(s.formControlSelect, s.formControlInput)} component="select">{formatMessage(messages.forceUpdate)}
                      <option value="1">{formatMessage(messages.active)}</option>
                      <option value="0">{formatMessage(messages.inactive)}</option>
                    </Field>
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={12} lg={6}>
                <Field name="orderAcceptInterval" type="text" component={this.renderAddonFormControl} label={formatMessage(messages.orderAcceptInterval)} maxlength={3} addonLabel={formatMessage(messages.seconds)} />
              </Col>
              <Col lg={6} md={6} sm={6} xs={12}>
                <FormGroup>
                  <div>
                    <label>{formatMessage(messages.uploadRequestTone)}</label>
                    {
                      !requestTone && <div className={cx(s.formControlInput)}>
                        <TripToneDropzone
                          className={cx('toneInput')}
                          subText={formatMessage(messages.maximumUploadSizeLabel)}
                          defaultMessage={formatMessage(messages.chooseFile)}
                        />
                      </div>
                    }
                    {
                      requestTone && <div className={s.audioSection}>
                        <div>{requestToneFile}</div>
                        <div className={s.flex}>
                          <div>
                            <span onClick={this.togglePlay}>{this.state.play ? <img src={pauseIcon} className={s.toneImg} /> : <img src={playIcon} className={s.toneImg} />}
                            </span>
                          </div>
                          <div className={cx(s.paddingLeft, 'toneCloseRTL')}>
                            <span onClick={() => deleteTone(requestTone)}><img src={deleteIcon} className={s.toneImg} /></span>
                          </div>
                        </div>
                        <audio id="audio-element" onEnded={() => this.setState({ play: false })}>
                          <source src={api.apiEndpoint + toneUploadDir + requestTone}></source>
                        </audio>
                      </div>
                    }
                  </div >
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} className={cx(s.textAlignRight, 'textLeftRTL')}>
                <FormGroup className={cx(s.space3, s.spaceTop5)}>
                  <div>
                    <Loader
                      type={"button"}
                      label={formatMessage(messages.submitButton)}
                      show={updateSiteSettings || submitting}
                      buttonType={'submit'}
                      className={cx(s.button, s.btnPrimary)}
                      disabled={updateSiteSettings || submitting}
                      isSuffix={true}
                    />
                  </div>
                </FormGroup>
              </Col>
            </Row>
          </form>
        </Container>
      </div>
    )
  }
}

const callSiteSettings = async (result, dispatch, props) => {
  const { refetch } = props
  await refetch()
  dispatch(siteSettings())
}

SiteSettingsForm = reduxForm({
  form: 'SiteSettingsForm',
  onSubmit: submit,
  validate,
  onSubmitSuccess: callSiteSettings
})(SiteSettingsForm);

const selector = formValueSelector('SiteSettingsForm')
const mapState = (state) => ({
  logo: selector(state, 'homeLogo'),
  ogImage: selector(state, 'ogImage'),
  appForceUpdate: selector(state, 'appForceUpdate'),
  favicon: selector(state, 'favicon'),
  updateSiteSettings: state.siteSettings.updateSiteSettings,
  requestTone: selector(state, 'requestTimeTone'),
  requestToneFile: selector(state, 'requestToneFile'),
  logoImageLoader: state.loader.logoImageLoader,
  favImageLoader: state.loader.favImageLoader,
  ogImageLoader: state.loader.ogImageLoader
})

const mapDispatch = {
  change,
  siteSettings,
  updateSiteSettingsDropDown,
  updateTempImages,
  deleteTone
}

export default injectIntl(
  compose(
    withStyles(s),
    connect(mapState, mapDispatch),
    graphql(gql`query {
      getCurrencies {
        id
        symbol
        isEnable
      }
    }`, {
      options: {
        fetchPolicy: 'network-only',
        ssr: false
      }
    })
  )(SiteSettingsForm));