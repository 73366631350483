import showToaster from '../../../helpers/showToaster';
import { loadAccount } from '../userAccount/userAccount';
import { getAllMenu as query, updateMenuStatus as mutation } from '../../../lib/graphql';
import {
	SHOP_MENU_UPDATE_START,
	SHOP_MENU_UPDATE_SUCCESS,
	SHOP_MENU_UPDATE_ERROR
} from '../../../constants/index';

import { demoRestriction } from '../../../demo';
import demoRestrictionToaster from '../../../helpers/demoRestrictionToaster';

const updateMenuStatus = (id, status, currentPage) => {
	return async (dispatch, getState, { client }) => {

		try {

			let errorMessage;
			if (demoRestriction.manageMenu && demoRestriction.manageMenu.indexOf(id) >= 0) {
				demoRestrictionToaster();
				return;
			}
			dispatch({
				type: SHOP_MENU_UPDATE_START,
				payload: {
					updateLoading: true
				}
			});

			const { data } = await client.mutate({
				mutation,
				variables: {
					id,
					status: status == 'false' ? 0 : 1
				},
				refetchQueries: [{
					query,
					variables: {
						currentPage
					}
				}]
			});

			if (data?.updateMenuStatus?.status === 200) {
				dispatch({
					type: SHOP_MENU_UPDATE_SUCCESS,
					payload: {
						updateLoading: false
					}
				});
				showToaster({ messageId: 'updateMenu', toasterType: 'success' })
				dispatch(loadAccount())
			} else {
				dispatch({
					type: SHOP_MENU_UPDATE_ERROR,
					payload: {
						updateLoading: false
					}
				});
				showToaster({ messageId: 'commonError', toasterType: 'error', requestContent: data?.updateMenuStatus?.errorMessage })
			}

		} catch (error) {
			showToaster({ messageId: 'catchError', toasterType: 'error', requestContent: error })
			dispatch({
				type: SHOP_MENU_UPDATE_ERROR,
				payload: {
					updateLoading: false
				}
			});
		}
	}
};

export default updateMenuStatus;