exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".AddSubMenuForm-root-32--T {\n  padding-left: 0;\n  padding-right: 0;\n  min-height: 100vh;\n  height: 100%;\n  width: 100%;\n  background: #d4d7e5;\n}\n.AddSubMenuForm-container-1hwRO {\n  margin: 0 auto;\n}\n.AddSubMenuForm-errorMessage-2rK3p{\n  color: red\n}", "", {"version":3,"sources":["/root/web/src/components/Shop/ManageMenu/SubMenu/AddSubMenuForm/AddSubMenuForm.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,aAAa;EACb,YAAY;EACZ,oBAAoB;CACrB;AACD;EACE,eAAe;CAChB;AACD;EACE,UAAU;CACX","file":"AddSubMenuForm.css","sourcesContent":[".root {\n  padding-left: 0;\n  padding-right: 0;\n  min-height: 100vh;\n  height: 100%;\n  width: 100%;\n  background: #d4d7e5;\n}\n.container {\n  margin: 0 auto;\n}\n.errorMessage{\n  color: red\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"root": "AddSubMenuForm-root-32--T",
	"container": "AddSubMenuForm-container-1hwRO",
	"errorMessage": "AddSubMenuForm-errorMessage-2rK3p"
};