import { editDriver } from '../../../actions/siteadmin/Users/editDriver';
import { demoRestriction } from '../../../actions/siteadmin/demoRestriction';

const submit = async (values, dispatch) => {
    const isRestrict = await dispatch(demoRestriction());
    if (isRestrict) return;
    await dispatch(
        editDriver(
            {
                id: values?.id,
                firstName: values?.firstName,
                lastName: values?.lastName,
                email: values?.email,
                password: values?.password ? values?.password : undefined,
                phoneDialCode: values?.phoneDialCode,
                phoneNumber: values?.phoneNumber,
                vehicleName: values?.vehicleName,
                vehicleNumber: values?.vehicleNumber,
                vehicleType: values?.vehicleType,
                userStatus: values?.userStatus,
                isBan: values?.isBan,
                phoneCountryCode: values?.phoneCountryCode
            }
        )
    )
};

export default submit;