export {
    getAllCommonSettingsData,
    getCurrenciesData,
    getCurrencyRatesData,
    sendForgotPasswordLink,
    updateForgotPassword,
    verifyOtpCode,
    updatePhoneNumber,
    updateConfigSettingsMutation,
    getCurrencyRatesQuery,
    getCurrency,
    updateCurrency,
    setBaseCurrencyMutation,
    updatePartnerHomepage,
    updatePromoCode,
    deletePromoCode,
    getPromoCodes,
    updateTempImages,
    updateSiteSettingsMutation,
    updateTone,
    updateStaticPage,
    getEditStaticPage,
    uploadInsuranceImage,
    uploadLicenceBackImage,
    uploadLicenceFrontImage,
    uploadProfileImage,
    uploadRcbookImage,
    allowPaymentCurrencyMutation,
    getStripeSettings
} from "./common";

export {
    uploadShopDocumentMutation,
    updateShopProfile,
    checkShopUser,
    shopUserLogin,
    shopUserLogout,
    updatePreparationTimeMutation,
    shopUserActivityChange,
    createShopUserMutation,
    deleteShop,
    updateShopMutation,
    updateShopStatus,
    uploadDocument
} from "./store";

export {
    getAllSubMenu,
    deleteItem,
    getAllMenu,
    deleteMenu,
    deleteSubMenu,
    getSubMenu,
    itemAvailable,
    updateSubMenuStatus,
    updateMenuStatus,
    updateItemStatus,
    updateItemMutation,
    updateMenu,
    addMenuMutation
} from "./menu";

export {
    updateModifierMutation,
    getAllModifierQuery,
    deleteModifierMutation,
    getAllShopModifierQuery,
    getCartModifierGroupQuery,
    getCartModifierItemQuery,
    getModifierQuery,
    updateModifierStatusMutation
} from "./modifier";

export {
    getPendingOrdersQuery,
    updateDeliveryStatusMutation,
    getOrders,
    updateOrderStatus
} from "./order";

export {
    userAccountQuery
} from './user';

export {
    getPaymentMethods,
    getPayouts,
    removePayout,
    setDefaultPayout,
    updatePayoutMutation,
    getPaymentMethodsQuery,
    updatePaymentMutation,
    addPayoutMutation
} from './payout';

export {
    createAdminRoleMutation,
    deleteAdminRoleMutation,
    getPrivilegesQuery,
    getAllAdminRoles
} from './adminRoles';

export {
    getAllAdminUsers,
    createAdminUserMutation,
    deleteAdminUserMutation,
    getAdminUserQuery
} from './adminUser';

export {
    addCategory,
    deleteCategory,
    updateCategory,
    updateCategoryImage,
    updateCategoryPrivilege,
    updateCategoryStatus
} from './category';

export {
    updateDeliveryVehicle,
    deleteDeliveryVehicle,
    updateDeliveryVehicleImage,
    updateDeliveryVehicleStatus
} from './deliveryModule';

export {
    updateCancelReason,
    checkUserAvailability,
    deleteUser,
    updateDriver,
    getAllDrivers,
    updateRider,
    updateVehicle,
    getAllVehicles,
    getAllCancelReason,
    removeCancelReason,
    getContentPageDetails,
    deleteContentPage,
    updateContentPageStatusMutation,
    getSiteSettings,
    updateHomePageAbout,
    updateHomePageCity,
    updateHomePageDriver,
    updateHomePageHome,
    updateHomePageFooter,
    updateHomePageSafety,
    updateHomePageSignup
} from './commonSiteAdmin';