import updateShopProfile from '../../../actions/shop/editProfile/updateShopProfile';
import showToaster from '../../../helpers/showToaster';
import { users } from '../../../demo';
import demoRestrictionToaster from '../../../helpers/demoRestrictionToaster';

async function submit(values, dispatch) {

    if(values.id === users.store.id){
        demoRestrictionToaster();
        return;
    }

    let profilePicError;

    values.picture ? profilePicError = false : profilePicError = true;

    profilePicError ? showToaster({ messageId: 'uploadStoreImg', toasterType: 'error' }) : await dispatch(updateShopProfile(values))

}

export default submit;