exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".TransactionList-btnPadding-224Ys {\n     padding: 7px 10px !important;\n}\n.TransactionList-iconWidth-2Zjvc {\n    width: 100%;\n    max-width: 20px;\n    margin-right: 3px;\n}\n.TransactionList-linkColor-89d8k {\n    color: #0CBCE2 !important;\n    text-decoration: none !important;\n}\n.TransactionList-inactive-OXEv3 {\n    opacity: 0.5;\n}\n.TransactionList-alignCenter-3Qr5x {\n    text-align: center !important;\n}", "", {"version":3,"sources":["/root/web/src/components/Shop/TransactionManagement/TransactionList/TransactionList.css"],"names":[],"mappings":"AAAA;KACK,6BAA6B;CACjC;AACD;IACI,YAAY;IACZ,gBAAgB;IAChB,kBAAkB;CACrB;AACD;IACI,0BAA0B;IAC1B,iCAAiC;CACpC;AACD;IACI,aAAa;CAChB;AACD;IACI,8BAA8B;CACjC","file":"TransactionList.css","sourcesContent":[".btnPadding {\n     padding: 7px 10px !important;\n}\n.iconWidth {\n    width: 100%;\n    max-width: 20px;\n    margin-right: 3px;\n}\n.linkColor {\n    color: #0CBCE2 !important;\n    text-decoration: none !important;\n}\n.inactive {\n    opacity: 0.5;\n}\n.alignCenter {\n    text-align: center !important;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"btnPadding": "TransactionList-btnPadding-224Ys",
	"iconWidth": "TransactionList-iconWidth-2Zjvc",
	"linkColor": "TransactionList-linkColor-89d8k",
	"inactive": "TransactionList-inactive-OXEv3",
	"alignCenter": "TransactionList-alignCenter-3Qr5x"
};