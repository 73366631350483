export const users = {
    store: {
        email: 'demo@radicalstart.com',
        password: 'qwerty123',
        id: '94201570-3af2-11ec-8487-6b49a290f161'
    },
    admin: {
        email: 'admin@radicalstart.com',
        password: 'qwerty123'
    }
};

export const salesUser = ['93d95080-17a8-11ec-aae9-496a27a49970'];

export const restrictionErrorMessage = {
    title: 'Demo Application!',
    message: 'Sorry, you are not allowed to change or remove the demo application information.'
};

export const exportRestrictionErrorMessage = {
    title: 'Demo Application!',
    message: 'Sorry, you are not allowed to export the demo application information.'
};

export const exportPushNotificationErrorMessage = {
    title: 'Demo Application!',
    message: 'Sorry, we do not send push notifications for the demo admin panel application changes.'
};

export const manageCategory = [12, 27, 29, 31, 32, 33, 34, 35, 36, 38];
export const manageVehicleTypes = [1, 2];
export const manageCancelReasons = [40, 41, 42, 43, 44, 45, 46, 47, 48, 49];


export const demoRestriction = {
    manageDietaryTypes: [],
    manageMenu: [12, 11],
    manageSubMenu: [23, 22, 21],
    manageItem: [52, 53, 54, 55, 56, 57]
};

export const promoCodeErrorMessage = {
    title: "Demo Application",
    message: "Heads-up! The push notifications are disabled on the demo website."
};