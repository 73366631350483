import React, { Component } from 'react';

import { Field, reduxForm, getFormValues, change, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl, FormattedMessage } from 'react-intl';
import cx from 'classnames';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FormGroup from 'react-bootstrap/FormGroup';
import FormControl from 'react-bootstrap/FormControl';
import Container from 'react-bootstrap/Container';

import Loader from '../../Common/Loader/Loader';
import ImageUploadComponent from '../../ImageUploadComponent/ImageUploadComponent';

import messages from '../../../locale/messages';
import submit from './submit';
import validate from './validate';
import { api, homepageUploadDir } from '../../../config';

import s from './DriverSettingsForm.css';


export class DriverSettingsForm extends Component {

	renderField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder }) => {
		const { formatMessage } = this.props.intl
		return (
			<Form.Group>
				<Form.Label>{label}</Form.Label>
				<Form.Control {...input} placeholder={placeholder} type={type} className={cx(fieldClass, s.formControlInput)} />
				{touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
			</Form.Group>
		)
	}

	renderFormControlTextArea = ({ input, label, meta: { touched, error }, children, className }) => {
		const { formatMessage } = this.props.intl
		return (
			<div>
				<FormGroup className={s.space3}>
					<div>
						<label className={s.labelText} >{label}</label>
					</div>
					<div>
						<FormControl
							{...input}
							className={className}
							placeholder={label}
							as="textarea"
							rows="4"
						>
							{children}
						</FormControl>
						{touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
					</div>
				</FormGroup>
			</div>
		);
	}

	render() {
		const { handleSubmit, safetyGridImage4, safetyGridImage5, safetyGridImage6, loading, submitting } = this.props;
		const { safetyGridImage4Loader, safetyGridImage5Loader, safetyGridImage6Loader } = this.props;
		const { formatMessage } = this.props.intl;
		return (
			<div className={cx(s.space5, s.spaceTop5)}>
				<Container fluid>
					<Row>
						<Col xl={10} lg={12} md={12} sm={12} xs={12} className={s.blackCenter}>
							<div className={s.blackCenterSection}>
								<Form className={s.fullWidth} onSubmit={handleSubmit(submit)}>
									<Col lg={12} md={12} sm={12} xs={12}>
										<h1 className={s.titleTextAdmin}>{formatMessage(messages.signupSectionSettings)}</h1>
									</Col>
									<Col lg={12} md={12} sm={12} xs={12}>
										<Row>
											<Col lg={4} md={12} sm={12} xs={12}>
												<Form.Group className={s.space3}>
													<div className={cx(s.profileImgSection, s.profileImgWidth)}>
														<div>
															<ImageUploadComponent
																className={cx(s.btnSecondary, s.profileNoPadding)}
																subTextClass={s.subText}
																subText={formatMessage(messages.maximumUploadSizeLabel)}
																defaultMessage={formatMessage(messages.chooseFile)}
																loaderName={'safetyGridImage6Loader'}
																postUrl={api.apiEndpoint + '/uploadHomepageImage'}
																loader={safetyGridImage6Loader}
																fieldName={'safetyGridImage6'}
																formName={'DriverSettingsForm'}
																imageSrc={safetyGridImage6 ? api.apiEndpoint + homepageUploadDir + safetyGridImage6 : null}
																label={formatMessage(messages.signupImage3)}
																inputContainer={'.dzInputContainerSafetyImage3'}
																inputContainerClass={'dzInputContainerSafetyImage3'}
																isDemoRestriction
															/>
														</div>
													</div>
												</Form.Group>
											</Col>
											<Col lg={4} md={12} sm={12} xs={12}>
												<Form.Group className={s.space3}>
													<div className={cx(s.profileImgSection, s.profileImgWidth)}>
														<div>
															<ImageUploadComponent
																className={cx(s.btnSecondary, s.profileNoPadding)}
																subTextClass={s.subText}
																subText={formatMessage(messages.maximumUploadSizeLabel)}
																defaultMessage={formatMessage(messages.chooseFile)}
																loaderName={'safetyGridImage4Loader'}
																postUrl={api.apiEndpoint + '/uploadHomepageImage'}
																loader={safetyGridImage4Loader}
																fieldName={'safetyGridImage4'}
																formName={'DriverSettingsForm'}
																imageSrc={safetyGridImage4 ? api.apiEndpoint + homepageUploadDir + safetyGridImage4 : null}
																label={formatMessage(messages.safetyImage1)}
																inputContainer={'.dzInputContainerSafetyImage1'}
																inputContainerClass={'dzInputContainerSafetyImage1'}
																isDemoRestriction
															/>
														</div>
													</div>
												</Form.Group>
											</Col>
											<Col lg={4} md={12} sm={12} xs={12}>
												<Form.Group className={s.space3}>
													<div className={cx(s.profileImgSection, s.profileImgWidth)}>
														<div>
															<ImageUploadComponent
																className={cx(s.btnSecondary, s.profileNoPadding)}
																subTextClass={s.subText}
																subText={formatMessage(messages.maximumUploadSizeLabel)}
																defaultMessage={formatMessage(messages.chooseFile)}
																loaderName={'safetyGridImage5Loader'}
																postUrl={api.apiEndpoint + '/uploadHomepageImage'}
																loader={safetyGridImage5Loader}
																fieldName={'safetyGridImage5'}
																formName={'DriverSettingsForm'}
																imageSrc={safetyGridImage5 ? api.apiEndpoint + homepageUploadDir + safetyGridImage5 : null}
																label={formatMessage(messages.safetyImage2)}
																inputContainer={'.dzInputContainerSafetyImage2'}
																inputContainerClass={'dzInputContainerSafetyImage2'}
																isDemoRestriction
															/>
														</div>
													</div>
												</Form.Group>
											</Col>
										</Row>
										<Row>
											<Col lg={12} md={12} sm={12} xs={12}>
												<Form.Group className={s.space3}>
													<div>
														<Field name="safetyGridTitle2" type="text" component={this.renderField} label={formatMessage(messages.driverAppTitle)} />
													</div>
												</Form.Group>
											</Col>
										</Row>

										<Row>
											<Col lg={12} md={12} sm={12} xs={12}>
												<Form.Group className={s.space3}>
													<div>
														<Field name="safetyGridContent2" type="text" component={this.renderFormControlTextArea} label={formatMessage(messages.driverAppContent)} />
													</div>
												</Form.Group>
											</Col>
										</Row>

										<Row>
											<Col lg={6} md={12} sm={12} xs={12}>
												<Form.Group className={s.space3}>
													<div>
														<Field name="safetyGridLink3" type="text" component={this.renderField} label={formatMessage(messages.playStoreLink)} />
													</div>
												</Form.Group>
											</Col>
											<Col lg={6} md={12} sm={12} xs={12}>
												<Form.Group className={s.space3}>
													<div>
														<Field name="safetyGridLink4" type="text" component={this.renderField} label={formatMessage(messages.appStoreLink)} />
													</div>
												</Form.Group>
											</Col>
										</Row>
										<Row>
											<Col lg={12} md={12} sm={12} xs={12} className={cx(s.textAlignRight, s.spaceTop1)}>
												<Form.Group className={s.noMargin}>
													<div className={s.displayInlineBlock}>
														<Loader
															type={"button"}
															label={formatMessage(messages.submitButton)}
															show={loading}
															buttonType={'submit'}
															className={cx(s.button, s.btnPrimary)}
															disabled={submitting || loading}
															isSuffix={true}
														/>
													</div>
												</Form.Group>
											</Col>
										</Row>
									</Col>
								</Form>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		)
	}
}

DriverSettingsForm = reduxForm({
	form: 'DriverSettingsForm',
	onSubmit: submit,
	validate
})(DriverSettingsForm);

const selector = formValueSelector('DriverSettingsForm')
const mapState = (state) => ({
	safetyGridImage4: selector(state, 'safetyGridImage4'),
	safetyGridImage5: selector(state, 'safetyGridImage5'),
	safetyGridImage6: selector(state, 'safetyGridImage6'),
	loading: state.loader.DriverSettingsForm,
	safetyGridImage4Loader: state.loader.safetyGridImage4Loader,
	safetyGridImage5Loader: state.loader.safetyGridImage5Loader,
	safetyGridImage6Loader: state.loader.safetyGridImage6Loader
})

const mapDispatch = {

}

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(DriverSettingsForm)));